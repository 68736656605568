import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Box,
  Avatar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import { IModuleComment } from "app/pages/Workplace/slice/type";

interface CommentCardProps {
  comment: IModuleComment;
  onEdit: (id: string, newContent: string) => void;
  onDelete: (id: string) => void;
  onCommentClick: (comment: IModuleComment) => void;
}

const CommentCard: React.FC<CommentCardProps> = ({
  comment,
  onEdit,
  onDelete,
  onCommentClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(comment.content);
  const [editError, setEditError] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleSaveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (editedContent.trim().length < 3) {
      setEditError("Comment must be at least 3 characters long");
      return;
    }
    onEdit(comment.id, editedContent);
    setIsEditing(false);
    setEditError("");
  };

  const handleCancelClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditedContent(comment.content);
    setIsEditing(false);
    setEditError("");
  };

  const handleDeleteClick = () => {
    onDelete(comment.id);
    handleMenuClose();
  };

  return (
    <Card
      sx={{
        mb: 1,
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
        cursor: "pointer",
        "&.MuiPaper-root": {
          marginTop: "8px",
          height: "unset",
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      }}
      onClick={() => onCommentClick(comment)}
    >
      <CardContent sx={{ p: 2 }}>
        {isEditing ? (
          <Box onClick={(e) => e.stopPropagation()}>
            <TextField
              fullWidth
              value={editedContent}
              onChange={(e) => {
                setEditedContent(e.target.value);
                setEditError("");
              }}
              placeholder="Comment or add others with @"
              variant="outlined"
              size="small"
              error={!!editError}
              helperText={editError}
              sx={{
                mb: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  "& fieldset": {
                    borderColor: "#e0e0e0",
                  },
                  "&:hover fieldset": {
                    borderColor: "#bdbdbd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1976d2",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ mr: 1, color: "#1976d2", cursor: "pointer" }}
                onClick={handleCancelClick}
              >
                Cancel
              </Typography>
              <IconButton
                onClick={handleSaveClick}
                sx={{
                  bgcolor: editedContent.trim() ? "#1976d2" : "#e0e0e0",
                  color: "white",
                  "&:hover": {
                    bgcolor: editedContent.trim() ? "#1565c0" : "#e0e0e0",
                  },
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Avatar
                src={comment.avatar}
                alt={comment.username}
                sx={{ width: 32, height: 32, mr: 1 }}
              />
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {comment.username}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {comment.content}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {new Date(comment.date).toLocaleString(undefined, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <IconButton
                onClick={handleMenuOpen}
                size="small"
                sx={{ padding: 0 }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </Box>
          </>
        )}
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </Card>
  );
};

export default CommentCard;

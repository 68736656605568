import {
  call,
  put,
  takeLatest,
  all,
  select,
  take,
  fork,
} from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { workplaceActions as actions } from ".";
import {
  ICreateElement,
  IDeleteElement,
  IGetModulePayload,
  IModuleComment,
  IUpdateElementSequence,
  InvalidBlock,
} from "./type";
import { IAPICallConfig } from "app/API/types";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  selectActiveCommentBlockId,
  selectModule,
  selectSelectedFile,
} from "./selector";
import { validateObject } from "app/components/Form/Validation/editorValidation";
import {
  extractCommentsFromModule,
  extractPhrasesByIds,
  findElementParents,
  findElementsWithMissingImageUrl,
  parseLockRes,
} from "helper/helperFunctions";
import { ILanguage } from "utils/type";
import { clearLocalStorage } from "utils/localStorage";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { selectGlossaries } from "app/pages/Glossary/slice/selector";
import { createFileUploaderChannel } from "utils/upload";
import { string } from "yup";

function* updateSeq(item: Record<string, any>) {
  if (item.sequence_no >= 0) {
    const route: IAPICallConfig = {
      method: "POST",
      isSecureRoute: true,
      route: routes.content.updateContentSequence,
      body: {
        content_id: item.id,
        content_sequence_number: item.sequence_no,
      },
    };
    const response: any = yield call(makeCall, { ...route });
    return response;
  }
}

function* updateElementSequence(element: IUpdateElementSequence) {
  try {
    const route: IAPICallConfig = {
      method: "POST",
      isSecureRoute: true,
      route: "",
      body: {},
    };

    switch (element.type) {
      case "topics":
        route.route = routes.topic.updateSequence;
        route.body = {
          topic_id: element.id,
          topic_sequence_number: element.sequence_no,
        };
        break;
      case "lessons":
        route.route = routes.lesson.updateLessonSequence;
        route.body = {
          lesson_id: element.id,
          lesson_sequence_number: element.sequence_no,
        };
        break;
      case "points":
        route.route = routes.point.updatePointSequence;
        route.body = {
          point_id: element.id,
          point_sequence_number: element.sequence_no,
        };
        break;
      case "subpoints":
        route.route = routes.subPoint.updateSubPointSequence;
        route.body = {
          sub_point_id: element.id,
          sub_point_sequence_number: element.sequence_no,
        };
        break;
      default:
        console.log("Unknown request");
        return {
          status: "rejected",
          v: { code: "1", message: "Unknown request type" },
        };
    }

    const response = yield call(makeCall, {
      ...route,
    });

    if (response.code !== "0") throw new Error("Invalid status");
    return { status: "fulfilled", v: response };
  } catch (err: any) {
    return {
      e: err,
      status: "rejected",
      el: {
        type: element.type,
        id: element.id,
        sequence_no: element.sequence_no,
      },
    };
  }
}

function* apiCall(
  item: Record<string, any>,
  type: "create" | "update" | "delete"
) {
  const route: IAPICallConfig = {
    method: "POST",
    isSecureRoute: true,
    route: "",
  };
  const regex = /<\s*(i|b)\s*>(.*?)<\s*\/\s*\1\s*>/g;
  const { parent_id } = item;
  switch (item.type) {
    case "topic":
      switch (type) {
        case "update":
          route["route"] = routes.topic.updateTitle;
          route["body"] = {
            title: item.title,
            topic_id: item.element_id,
          };
          break;
      }
      break;
    case "lesson":
      switch (type) {
        case "update":
          route["route"] = routes.lesson.updateLesson;
          route["body"] = {
            title: item.title,
            lesson_id: item.element_id,
          };
          break;
      }
      break;
    case "point":
      switch (type) {
        case "update":
          route["route"] = routes.point.updatePoint;
          route["body"] = {
            title: item.title,
            point_id: item.element_id,
          };
          break;
      }
      break;
    case "sub_point":
      switch (type) {
        case "update":
          route["route"] = routes.subPoint.updateSubPoint;
          route["body"] = {
            title: item.title,
            sub_point_id: item.element_id,
          };
          break;
      }
      break;
    case "paragraph":
      switch (type) {
        case "create":
          route["route"] = routes.paragraph.create;
          if (item.data.text.match(regex)) {
            route["body"] = {
              parent_id,
              content_sequence_number: item.isCreateElement
                ? --item.sequence_no || 1
                : item.sequence_no,
              content: item.data.text,
              italic: item.italic,

              clean_content: item.data.text.replace(regex, "$2"),
            };
          } else {
            route["body"] = {
              parent_id,
              content_sequence_number: item.isCreateElement
                ? --item.sequence_no || 1
                : item.sequence_no,
              content: item.data.text,
              italic: item.italic,
            };
          }
          break;
        case "update":
          const module = yield select(selectModule);
          const selectedItems = yield call(
            findElementParents,
            parent_id,
            module.result
          );

          let update_paragraph_id = "";
          switch (selectedItems.length) {
            case 1:
              update_paragraph_id =
                module.result.topics[parent_id].contents[item.id].contents[0]
                  .id;
              break;
            case 2:
              update_paragraph_id =
                module.result.topics[selectedItems[0]].lessons[selectedItems[1]]
                  .contents[item.id].contents[0].id;
              break;
            case 3:
              update_paragraph_id =
                module.result.topics[selectedItems[0]].lessons[selectedItems[1]]
                  .points[selectedItems[2]].contents[item.id].contents[0].id;
              break;
            case 4:
              update_paragraph_id =
                module.result.topics[selectedItems[0]].lessons[selectedItems[1]]
                  .points[selectedItems[2]].sub_points[selectedItems[3]]
                  .contents[item.id].contents[0].id;
              break;
            default:
              console.log("--->NO PARAGRAPH ID");
          }
          route["route"] = routes.paragraph.update;
          if (item.data.text.match(regex)) {
            route["body"] = {
              paragraph_id: update_paragraph_id,
              paragraph_content: item.data.text,
              italic: item.italic,

              clean_content: item.data.text.replace(regex, "$2"),
            };
          } else {
            route["body"] = {
              paragraph_id: update_paragraph_id,
              paragraph_content: item.data.text,
              italic: item.italic,
            };
          }
          break;
        case "delete":
          const _module = yield select(selectModule);
          const _selectedItems = yield call(
            findElementParents,
            parent_id,
            _module.result
          );
          let delete_paragraph_id = "";
          switch (_selectedItems.length) {
            case 1:
              delete_paragraph_id =
                _module.result.topics[parent_id].contents[item.id].contents[0]
                  .id;
              break;
            case 2:
              delete_paragraph_id =
                _module.result.topics[_selectedItems[0]].lessons[
                  _selectedItems[1]
                ].contents[item.id].contents[0].id;
              break;
            case 3:
              delete_paragraph_id =
                _module.result.topics[_selectedItems[0]].lessons[
                  _selectedItems[1]
                ].points[_selectedItems[2]].contents[item.id].contents[0].id;
              break;
            case 4:
              delete_paragraph_id =
                _module.result.topics[_selectedItems[0]].lessons[
                  _selectedItems[1]
                ].points[_selectedItems[2]].sub_points[_selectedItems[3]]
                  .contents[item.id].contents[0].id;
              break;
            default:
              console.log("--->NO PARAGRAPH ID");
          }
          route["route"] = routes.paragraph.delete;
          route["body"] = {
            paragraph_id: delete_paragraph_id,
          };
          break;
      }
      break;
    case "list":
      switch (type) {
        case "create":
          let un_clean_content = false;
          for (let i = 0; i < item.data.items.length; i++) {
            if (item.data.items[i].match(regex)) {
              un_clean_content = true;
              break;
            }
          }
          const baseBody = {
            parent_id,
            content_sequence_number: item.isCreateElement
              ? --item.sequence_no || 1
              : item.sequence_no,

            list_items: item.data.items.reduce((acc, currentValue, index) => {
              acc[`${index + 1}`] = currentValue;
              return acc;
            }, {}),
          };

          if (un_clean_content) {
            baseBody["clean_content"] = item.data.items.reduce(
              (acc, currentValue, index) => {
                acc[`${index + 1}`] = currentValue.match(regex)
                  ? currentValue.replace(regex, "$2")
                  : currentValue;
                return acc;
              },
              {}
            );
          }
          route["body"] = baseBody;
          route["route"] = routes.list.create;
          break;
        case "delete":
          route["route"] = routes.list.delete;
          route["body"] = {
            content_id: item.id,
          };
          break;
        case "update":
          const _listModule = yield select(selectModule);
          let update_unclean_content = false;
          for (let i = 0; i < item.data.items.length; i++) {
            if (item.data.items[i].match(regex)) {
              update_unclean_content = true;
              break;
            }
          }
          const selectedItems = yield call(
            findElementParents,
            parent_id,
            _listModule.result
          );
          let update_list_id = "";
          switch (selectedItems.length) {
            case 1:
              update_list_id =
                _listModule.result.topics[parent_id].contents[item.id].contents[
                  item.index
                ].id;
              break;
            case 2:
              update_list_id =
                _listModule.result.topics[selectedItems[0]].lessons[
                  selectedItems[1]
                ].contents[item.id].contents[item.index].id;
              break;
            case 3:
              update_list_id =
                _listModule.result.topics[selectedItems[0]].lessons[
                  selectedItems[1]
                ].points[selectedItems[2]].contents[item.id].contents[
                  item.index
                ].id;
              break;
            case 4:
              update_list_id =
                _listModule.result.topics[selectedItems[0]].lessons[
                  selectedItems[1]
                ].points[selectedItems[2]].sub_points[selectedItems[3]]
                  .contents[item.id].contents[item.index].id;
              break;
            default:
              console.log("--->NO LIST ID");
          }
          route["route"] = routes.list.update;
          if (update_unclean_content) {
            route["body"] = {
              list_item_id: update_list_id,
              list_item_content: item.data.items[0],
              list_item_sequence_number: ++item.index,

              clean_content: item.data.items[0].replace(regex, "$2"),
            };
          } else {
            route["body"] = {
              list_item_id: update_list_id,
              list_item_content: item.data.items[0],
              list_item_sequence_number: ++item.index,
            };
          }
      }
      break;
    case "table":
      switch (type) {
        case "create":
          route["route"] = routes.table.create;
          const tableBody = item.data.content.reduce((acc, row, rowIndex) => {
            acc[rowIndex + 1] = row.reduce((obj, cell, cellIndex) => {
              obj[cellIndex + 1] = cell;
              return obj;
            }, {});
            return acc;
          }, {});

          const tableCleanContent = item.data.content.reduce(
            (acc, row, rowIndex) => {
              acc[rowIndex + 1] = row.reduce((obj, cell, cellIndex) => {
                obj[cellIndex + 1] = cell.match(regex)
                  ? cell.replace(regex, "$2")
                  : cell;
                return obj;
              }, {});
              return acc;
            },
            {}
          );

          const hasUncleanContent = item.data.content.some((row) =>
            row.some((cell) => cell.match(regex))
          );

          route["body"] = {
            parent_id,
            content_sequence_number: item.isCreateElement
              ? --item.sequence_no || 1
              : item.sequence_no,
            table_cells: tableBody,

            ...(hasUncleanContent && { clean_content: tableCleanContent }),
          };
          break;
        case "update":
          const module = yield select(selectModule);
          const selectedItems = yield call(
            findElementParents,
            parent_id,
            module.result
          );
          let matchedObject;
          switch (selectedItems.length) {
            case 1:
              matchedObject = module.result.topics[parent_id].contents[
                item.id
              ].contents.find(
                (cnt) =>
                  cnt.row_number === item.cell.row &&
                  cnt.column_number === item.cell.col
              );
              break;
            case 2:
              matchedObject = module.result.topics[selectedItems[0]].lessons[
                selectedItems[1]
              ].contents[item.id].contents.find(
                (cnt) =>
                  cnt.row_number === item.cell.row &&
                  cnt.column_number === item.cell.col
              );
              break;
            case 3:
              matchedObject = module.result.topics[selectedItems[0]].lessons[
                selectedItems[1]
              ].points[selectedItems[2]].contents[item.id].contents.find(
                (cnt) =>
                  cnt.row_number === item.cell.row &&
                  cnt.column_number === item.cell.col
              );
              break;
            case 4:
              matchedObject = module.result.topics[selectedItems[0]].lessons[
                selectedItems[1]
              ].points[selectedItems[2]].sub_points[selectedItems[3]].contents[
                item.id
              ].contents.find(
                (cnt) =>
                  cnt.row_number === item.cell.row &&
                  cnt.column_number === item.cell.col
              );
              break;
            default:
              console.log("--->NO MATCHED TABLE OBJECT");
          }

          const item_row = item.cell.row;
          const item_col = item.cell.col;
          route["route"] = routes.table.update;
          if (item.data.content[item_row - 1][item_col - 1].match(regex)) {
            route["body"] = {
              table_cell_id: matchedObject.id,
              table_cell_content: item.data.content[item_row - 1][item_col - 1],

              clean_content: item.data.content[item_row - 1][
                item_col - 1
              ].replace(regex, "$2"),
            };
          } else {
            route["body"] = {
              table_cell_id: matchedObject.id,
              table_cell_content: item.data.content[item_row - 1][item_col - 1],
            };
          }
          break;
        case "delete":
          route["route"] = routes.table.delete;
          route["body"] = {
            content_id: item.id,
          };
          break;
      }
      break;
    default:
      return "";
  }
  const response: any = yield call(makeCall, { ...route });
  return response;
}

function* getModuleSaga(action: PayloadAction<IGetModulePayload>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.get,
      body: {
        module_id: action.payload.id,
      },
    });
    if (response.module) {
      yield put(
        defaultLayoutActions.setStyleProps(
          !!!Object.values(response.module?.topics || {})?.length
        )
      );
      yield put(actions.setModuleState(response.module));
    }
    yield put(actions.setIsModuleLoading(false));
  } catch (error: any) {
    yield put(actions.setIsModuleLoading(false));
    yield call(handleAPIError, error);
    yield put(defaultLayoutActions.setStyleProps(false));
    console.log("Error Getting Module", error);
  }
}

function* translateLanguageSaga(
  action: PayloadAction<{ lang: string; useGlossary: boolean }>
) {
  const module = yield select(selectModule);
  try {
    const glossary = yield select(selectGlossaries);
    let glossaries;
    if (glossary.result && action.payload.useGlossary) {
      glossaries = yield call(
        extractPhrasesByIds,
        glossary.result,
        module.selectedGlossaryIds,
        action.payload.lang
      );
    }
    let body;
    if (Object.keys(glossaries || {}).length) {
      body = {
        glossaries,
      };
    }
    body = {
      ...body,
      module_id: module.result.id,
      language: action.payload.lang,
    };
    const _response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.translate,
      body,
      isLockModule: true,
    });
    if (_response.code == "0") {
      yield put(defaultLayoutActions.setDownloadingStatus("success"));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      yield put(
        defaultLayoutActions.setGlobalLanguage(action.payload.lang as ILanguage)
      );
    } else if (_response.code == "1") {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Error,
          currentTitle: DEFAULT_TITLE.Fail,
          currentVariant: VARIANTS.Error as TypeOptions,
          count: uuidv4(),
        })
      );
    }
    yield put(actions.setIsContentLoading(false));
  } catch (err: any) {
    const errRes = JSON.parse(`${err.message}`);
    if (errRes) {
      if (errRes.status == 401) {
        yield call(handleAPIError, err);
      }
      const getModule = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: module.result.id,
        },
      });
      yield put(actions.setModuleState(getModule.module));
      if (getModule.module) {
        const items = yield call(parseLockRes, {
          response: errRes,
          data: getModule,
        });
        yield put(actions.setLockErrRes(items));
      }
    }
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsContentLoading(false));
  }
}

function* lockModuleSaga(action: PayloadAction<boolean>) {
  try {
    let failedRequests: Array<{
      elementId: string;
      elementType: string;
      elementNo: number;
    }> = [];
    let validationError = false;
    const module = yield select(selectModule);
    if (action.payload) {
      const elementsToUpdate = module.parentElsSequenceChange;
      if (elementsToUpdate || elementsToUpdate.length > 0) {
        const results: Array<{
          status: "fulfilled" | "rejected";
          v: {
            code: string;
            message: string;
          };
          el?: {
            id: string;
            sequence_no: number;
            type: string;
          };
        }> = yield all(
          elementsToUpdate.map((element) =>
            call(updateElementSequence, element)
          )
        );
        const failure = results.filter(
          (result) => result.status === "rejected"
        );
        if (failure) {
          failure.map((fail) =>
            failedRequests.push({
              elementId: fail?.el?.id || "",
              elementNo: fail?.el?.sequence_no || 0,
              elementType: fail?.el?.type || "",
            })
          );
        }
      }
      for (const itemId in module.content) {
        const contentItem = module.content[itemId];
        for (let index = 0; index < contentItem.created.length; index++) {
          let c = contentItem.created[index];
          const validationResult = yield call(
            validateObject,
            contentItem.created
          );
          if (validationResult.success) {
            try {
              c = { ...c, parent_id: itemId };
              const result = yield call(apiCall, c, "create");
              if (result.code == "0") {
                yield put(
                  actions.setContentRes({
                    id: itemId,
                    key: "created",
                    elementId: c.id,
                  })
                );
              }
            } catch (error: any) {
              failedRequests.push({
                elementId: itemId,
                elementNo: c?.sequence_no,
                elementType: c.type,
              });
              console.error("Request failed:", error);
              yield call(handleAPIError, error);
            }
          } else {
            validationError = true;
          }
        }
        for (let index = 0; index < contentItem.deleted.length; index++) {
          let c = contentItem.deleted[index];
          try {
            c = { ...c, parent_id: itemId };
            const result = yield call(apiCall, c, "delete");
            if (result.code == "0") {
              yield put(
                actions.setContentRes({
                  id: itemId,
                  key: "deleted",
                  elementId: c.id,
                })
              );
            }
          } catch (error: any) {
            failedRequests.push({
              elementId: itemId,
              elementNo: c?.sequence_no,
              elementType: c.type,
            });
            console.error("Request failed:", error);
            yield call(handleAPIError, error);
          }
        }
        for (let index = 0; index < contentItem.changed.length; index++) {
          let c = contentItem.changed[index];
          try {
            if (c?.sequence_no) {
              const result = yield call(updateSeq, c);
              if (result.code == "0") {
                yield put(
                  actions.setContentRes({
                    id: itemId,
                    key: "changed",
                    elementId: c.id,
                  })
                );
              }
            } else {
              const validationResult = yield call(
                validateObject,
                contentItem.changed
              );
              if (validationResult.success) {
                c = {
                  ...c,
                  parent_id: itemId,
                  isCreateElement: action.payload,
                };
                const result = yield call(apiCall, c, "update");
                if (result.code == "0") {
                  yield put(
                    actions.setContentRes({
                      id: itemId,
                      key: "changed",
                      elementId: c.id,
                    })
                  );
                }
              } else {
                validationError = true;
              }
            }
          } catch (error: any) {
            failedRequests.push({
              elementId: itemId,
              elementNo: c?.sequence_no,
              elementType: c.type,
            });
            console.error("Request failed:", error);
            yield call(handleAPIError, error);
          }
        }
      }
    }
    if (failedRequests.length == 0 && !validationError) {
      const response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: module.result.id,
        },
      });
      if (response?.module) {
        // const validationResult = yield call(validateContents, response.module);
        // if (validationResult) {
        try {
          const _response = yield call(makeCall, {
            method: "POST",
            isSecureRoute: true,
            route: routes.module.lock,
            body: {
              module_id: module.result.id,
              status: action.payload ? "locked" : "unlocked",
            },
            isLockModule: true,
          });
          if (_response.code === "0") {
            const getModule = yield call(makeCall, {
              method: "POST",
              isSecureRoute: true,
              route: routes.module.get,
              body: {
                module_id: module.result.id,
              },
              isLockModule: true,
            });
            if (getModule.module) {
              if (!action.payload) {
                const _res = { ...getModule.module };
                _res["paramLang"] = ILanguage.en;
                yield put(actions.setModuleState(_res));
                yield put(actions.clearContent());
              } else yield put(actions.setModuleState(getModule.module));
              yield put(
                defaultLayoutActions.setAppMessage({
                  currentMessage: DEFAULT_DESCRIPTION.Success,
                  currentTitle: DEFAULT_TITLE.Success,
                  currentVariant: VARIANTS.SUCCESS as TypeOptions,
                  count: uuidv4(),
                })
              );
            }
          }
        } catch (err: any) {
          const errRes = JSON.parse(`${err.message}`);
          if (errRes) {
            if (errRes.status == 401) {
              yield call(clearLocalStorage);
              yield put(defaultLayoutActions.clearUser());
            }
            const getModule = yield call(makeCall, {
              method: "POST",
              isSecureRoute: true,
              route: routes.module.get,
              body: {
                module_id: module.result.id,
              },
            });
            yield put(actions.setModuleState(getModule.module));
            if (getModule.module) {
              const items = yield call(parseLockRes, {
                response: errRes,
                data: getModule,
              });
              yield put(actions.setLockErrRes(items));
            }
          }
          yield put(
            defaultLayoutActions.setAppMessage({
              currentMessage: DEFAULT_DESCRIPTION.Error,
              currentTitle: DEFAULT_TITLE.Fail,
              currentVariant: VARIANTS.Error as TypeOptions,
              count: uuidv4(),
            })
          );
        }
        // } else {
        // yield put(actions.setModuleState(response.module));
        // }
      }
    } else {
      if (validationError) {
        yield put(actions.setIsValidationOpen(true));
      } else if (failedRequests.length) {
        yield put(
          actions.manageFailed({
            module: module.result,
            errors: failedRequests,
          })
        );
      }
    }
    yield put(actions.setIsModuleLoading(false));
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsModuleLoading(false));
    yield call(handleAPIError, error);
  }
}

function* manageContentSaga(action: PayloadAction<string | undefined>) {
  try {
    let failedRequests: Array<{
      elementId: string;
      elementType: string;
      elementNo: number;
    }> = [];
    const module = yield select(selectModule);
    let validationError = false;
    const elementsToUpdate = module.parentElsSequenceChange;

    if (elementsToUpdate || elementsToUpdate.length > 0) {
      const results: Array<{
        status: "fulfilled" | "rejected";
        v: {
          code: string;
          message: string;
        };
        el?: {
          id: string;
          sequence_no: number;
          type: string;
        };
      }> = yield all(
        elementsToUpdate.map((element) => call(updateElementSequence, element))
      );
      const failure = results.filter((result) => result.status === "rejected");
      if (failure) {
        failure.map((fail) =>
          failedRequests.push({
            elementId: fail?.el?.id || "",
            elementNo: fail?.el?.sequence_no || 0,
            elementType: fail?.el?.type || "",
          })
        );
      }
    }
    yield put(actions.clearInvalidBlocks());

    for (const itemId in module.content) {
      const contentItem = module.content[itemId];
      let invalidBlocks: InvalidBlock[] = [];

      for (let key of ["created", "changed"]) {
        if (contentItem[key].length > 0) {
          const validationResult = yield call(validateObject, contentItem[key]);
          if (!validationResult.success) {
            validationError = true;
            invalidBlocks = [
              ...invalidBlocks,
              ...validationResult.errors.map((error) => ({
                blockId: error.blockId,
                message: error.message,
              })),
            ];
          }
        }
      }

      if (invalidBlocks.length > 0) {
        yield put(
          actions.setInvalidBlocks({
            editorId: itemId,
            invalidBlocks: invalidBlocks,
          })
        );
      }

      for (let index = 0; index < contentItem.created.length; index++) {
        let c = contentItem.created[index];
        try {
          if (!validationError) {
            c = { ...c, parent_id: itemId, isCreateElement: action.payload };
            const result = yield call(apiCall, c, "create");
            if (result.code == "0") {
              yield put(
                actions.setContentRes({
                  id: itemId,
                  key: "created",
                  elementId: c.id,
                })
              );
            }
          }
        } catch (error: any) {
          yield call(handleAPIError, error);
          failedRequests.push({
            elementId: itemId,
            elementNo: c?.sequence_no,
            elementType: c.type,
          });
          console.error("Request failed:", error);
        }
      }

      for (let index = 0; index < contentItem.deleted.length; index++) {
        let c = contentItem.deleted[index];
        try {
          c = { ...c, parent_id: itemId, isCreateElement: action.payload };
          const result = yield call(apiCall, c, "delete");
          if (result.code == "0") {
            yield put(
              actions.setContentRes({
                id: itemId,
                key: "deleted",
                elementId: c.id,
              })
            );
          }
        } catch (error: any) {
          yield call(handleAPIError, error);
          failedRequests.push({
            elementId: itemId,
            elementNo: c?.sequence_no,
            elementType: c.type,
          });
          console.error("Request failed:", error);
        }
      }

      for (let index = 0; index < contentItem.changed.length; index++) {
        let c = contentItem.changed[index];
        try {
          if (c?.sequence_no) {
            const result = yield call(updateSeq, c);
            if (result.code == "0") {
              yield put(
                actions.setContentRes({
                  id: itemId,
                  key: "changed",
                  elementId: c.id,
                })
              );
            }
          } else {
            if (!validationError) {
              c = { ...c, parent_id: itemId, isCreateElement: action.payload };
              const result = yield call(apiCall, c, "update");
              if (result.code == "0") {
                yield put(
                  actions.setContentRes({
                    id: itemId,
                    key: "changed",
                    elementId: c.id,
                  })
                );
              }
            }
          }
        } catch (error: any) {
          failedRequests.push({
            elementId: itemId,
            elementNo: c?.sequence_no,
            elementType: c.type,
          });
          console.error("Request failed:", error);
          yield call(handleAPIError, error);
        }
      }
    }

    if (failedRequests.length == 0 && !validationError) {
      const response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: module.result.id,
        },
      });
      if (response.module) {
        yield put(actions.setModuleState(response.module));
      }
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      if (action.payload) {
        yield put(
          defaultLayoutActions.setStyleProps(
            !!!Object.values(response.module?.topics || {})?.length
          )
        );
        yield put(actions.setIsElementAdding(true));
        yield put(actions.setIsModuleLoading(false));
      }
    } else {
      if (action.payload) {
        //if creating an element succeeds and creating paragraph, lesson, table fails then update module.result so it can show the newly added element
        const getModule = yield call(makeCall, {
          method: "POST",
          isSecureRoute: true,
          route: routes.module.get,
          body: {
            module_id: module.result.id,
          },
        });
        if (getModule.module) {
          let ele;
          const parentItems = yield call(
            findElementParents,
            action.payload,
            getModule.module
          );
          switch (parentItems.length) {
            case 1:
              ele = {
                sequence_number:
                  getModule.module.topics[parentItems[0]].sequence_no,
                title: getModule.module.topics[parentItems[0]].title,
                modified_date:
                  getModule.module.topics[parentItems[0]].modified_date,
                contents: {},
              };
              break;
            case 2:
              ele = {
                sequence_number:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].sequence_no,
                title:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].title,
                modified_date:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].modified_date,
                contents: {},
              };
              break;
            case 3:
              ele = {
                sequence_number:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].sequence_no,
                title:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].title,
                modified_date:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].modified_date,
                contents: {},
              };
              break;
            case 4:
              ele = {
                sequence_number:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].sub_points[parentItems[3]]
                    .sequence_no,
                title:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].sub_points[parentItems[3]].title,
                modified_date:
                  getModule.module.topics[parentItems[0]].lessons[
                    parentItems[1]
                  ].points[parentItems[2]].sub_points[parentItems[3]]
                    .modified_date,
                contents: {},
              };
              break;
            default:
              ele = {
                sequence_number:
                  getModule.module.topics[parentItems[0]].sequence_no,
                title: getModule.module.topics[parentItems[0]].title,
                img_url: "NA",
                modified_date:
                  getModule.module.topics[parentItems[0]].modified_date,
                contents: {},
              };
          }
          yield put(
            actions.setModuleResult({
              element: ele,
              parentIds: parentItems,
              addedElementId: action.payload,
            })
          );
          yield put(
            defaultLayoutActions.setAppMessage({
              currentMessage: DEFAULT_DESCRIPTION.Success,
              currentTitle: DEFAULT_TITLE.Success,
              currentVariant: VARIANTS.SUCCESS as TypeOptions,
              count: uuidv4(),
            })
          );
        }
        yield put(actions.setIsElementAdding(true));
        yield put(actions.setIsModuleLoading(false));
        yield put(actions.setIsCreatingParentElement(false));
      }
      if (validationError) {
        yield put(actions.setIsValidationOpen(true));
      } else if (failedRequests.length) {
        yield put(
          actions.manageFailed({
            module: module.result,
            errors: failedRequests,
          })
        );
      }
    }
    yield put(actions.setIsContentLoading(false));
  } catch (error: any) {
    yield put(actions.setIsContentLoading(false));
    if (action.payload) {
      yield put(actions.setIsModuleLoading(false));
      yield put(actions.setIsCreatingParentElement(false));
    }
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield call(handleAPIError, error);
  }
}

function* createElementSaga(action: PayloadAction<ICreateElement>) {
  try {
    const route: IAPICallConfig = {
      method: "POST",
      isSecureRoute: true,
      route: "",
    };

    switch (action.payload.element_name) {
      case "topic":
        route["route"] = routes.topic.create;
        route["body"] = {
          topic_title: action.payload.title,
          module_id: action.payload.element_id,
          topic_sequence_number: action.payload.sequence_no,
        };
        break;
      case "lesson":
        route["route"] = routes.lesson.create;
        route["body"] = {
          lesson_title: action.payload.title,
          topic_id: action.payload.element_id,
          lesson_sequence_number: action.payload.sequence_no,
        };
        break;
      case "point":
        route["route"] = routes.point.create;
        route["body"] = {
          point_title: action.payload.title,
          lesson_id: action.payload.element_id,
          point_sequence_number: action.payload.sequence_no,
        };
        break;
      case "sub_point":
        route["route"] = routes.subPoint.create;
        route["body"] = {
          sub_point_title: action.payload.title,
          point_id: action.payload.element_id,
          sub_point_sequence_number: action.payload.sequence_no,
        };
        break;
    }

    const response: any = yield call(makeCall, {
      ...route,
    });

    if (response.code == "0") {
      let elId;
      switch (action.payload.element_name) {
        case "topic":
          elId = response.topic_id;
          break;
        case "lesson":
          elId = response.lesson_id;
          break;
        case "point":
          elId = response.point_id;
          break;
        case "sub_point":
          elId = response.sub_point_id;
          break;
        default:
          elId = response.topic_id;
      }
      yield put(actions.manageContent(elId));
    } else {
      yield put(actions.setIsModuleLoading(false));
    }
  } catch (error: any) {
    yield put(actions.setIsModuleLoading(false));
    yield put(actions.setIsCreatingParentElement(false));
    defaultLayoutActions.setAppMessage({
      currentMessage: DEFAULT_DESCRIPTION.Error,
      currentTitle: DEFAULT_TITLE.Fail,
      currentVariant: VARIANTS.Error as TypeOptions,
      count: uuidv4(),
    });
    yield call(handleAPIError, error);
  }
}

function* updateElementSaga(action: PayloadAction<ICreateElement>) {
  try {
    const route: IAPICallConfig = {
      method: "POST",
      isSecureRoute: true,
      route: "",
    };
    switch (action.payload.element_name) {
      case "topics":
        route["route"] = routes.topic.updateTitle;
        route["body"] = {
          title: action.payload.title,
          topic_id: action.payload.element_id,
        };
        break;
      case "lessons":
        route["route"] = routes.lesson.updateLesson;
        route["body"] = {
          title: action.payload.title,
          lesson_id: action.payload.element_id,
        };
        break;
      case "points":
        route["route"] = routes.point.updatePoint;
        route["body"] = {
          title: action.payload.title,
          point_id: action.payload.element_id,
        };
        break;
      case "subpoints":
        route["route"] = routes.subPoint.updateSubPoint;
        route["body"] = {
          title: action.payload.title,
          sub_point_id: action.payload.element_id,
        };
        break;
    }

    const response: any = yield call(makeCall, {
      ...route,
      body: {
        ...route["body"],
        language: action.payload?.language || ILanguage.en,
      },
    });
    if (response.code == "0") {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    }
  } catch (error: any) {
    defaultLayoutActions.setAppMessage({
      currentMessage: DEFAULT_DESCRIPTION.Error,
      currentTitle: DEFAULT_TITLE.Fail,
      currentVariant: VARIANTS.Error as TypeOptions,
      count: uuidv4(),
    }),
      yield put(actions.setIsModuleLoading(false));
    yield call(handleAPIError, error);
  }
}

function* deleteElementSaga(action: PayloadAction<IDeleteElement>) {
  try {
    let failedRequests: Array<{
      elementId: string;
      elementType: string;
      elementNo: number;
    }> = [];
    const module = yield select(selectModule);
    const elementsToUpdate = module.parentElsSequenceChange;

    if (elementsToUpdate || elementsToUpdate.length > 0) {
      const results: Array<{
        status: "fulfilled" | "rejected";
        v: {
          code: string;
          message: string;
        };
        el?: {
          id: string;
          sequence_no: number;
          type: string;
        };
      }> = yield all(
        elementsToUpdate.map((element) => call(updateElementSequence, element))
      );
      const failure = results.filter((result) => result.status === "rejected");
      if (failure) {
        failure.map((fail) =>
          failedRequests.push({
            elementId: fail?.el?.id || "",
            elementNo: fail?.el?.sequence_no || 0,
            elementType: fail?.el?.type || "",
          })
        );
      }
    }
    let validationError = false;
    for (const itemId in module.content) {
      const contentItem = module.content[itemId];
      let invalidBlocks: InvalidBlock[] = [];

      for (let key of ["created", "changed"]) {
        if (contentItem[key].length > 0) {
          const validationResult = yield call(validateObject, contentItem[key]);
          if (!validationResult.success) {
            validationError = true;
            invalidBlocks = [
              ...invalidBlocks,
              ...validationResult.errors.map((error) => ({
                blockId: error.blockId,
                message: error.message,
              })),
            ];
          }
        }
      }

      if (invalidBlocks.length > 0) {
        yield put(
          actions.setInvalidBlocks({
            editorId: itemId,
            invalidBlocks: invalidBlocks,
          })
        );
      }

      for (let index = 0; index < contentItem.created.length; index++) {
        let c = contentItem.created[index];
        if (!validationError) {
          try {
            c = { ...c, parent_id: itemId };
            const result = yield call(apiCall, c, "create");
            if (result.code == "0") {
              yield put(
                actions.setContentRes({
                  id: itemId,
                  key: "created",
                  elementId: c.id,
                })
              );
            }
          } catch (error: any) {
            failedRequests.push({
              elementId: itemId,
              elementNo: c?.sequence_no,
              elementType: c.type,
            });
            console.error("Request failed:", error);
            yield call(handleAPIError, error);
          }
        }
      }
      for (let index = 0; index < contentItem.deleted.length; index++) {
        let c = contentItem.deleted[index];
        try {
          c = { ...c, parent_id: itemId };
          const result = yield call(apiCall, c, "delete");
          if (result.code == "0") {
            yield put(
              actions.setContentRes({
                id: itemId,
                key: "deleted",
                elementId: c.id,
              })
            );
          }
        } catch (error: any) {
          failedRequests.push({
            elementId: itemId,
            elementNo: c?.sequence_no,
            elementType: c.type,
          });
          console.error("Request failed:", error);
          yield call(handleAPIError, error);
        }
      }
      for (let index = 0; index < contentItem.changed.length; index++) {
        let c = contentItem.changed[index];
        try {
          if (c?.sequence_no) {
            const result = yield call(updateSeq, c);
            if (result.code == "0") {
              yield put(
                actions.setContentRes({
                  id: itemId,
                  key: "changed",
                  elementId: c.id,
                })
              );
            }
          } else {
            if (!validationError) {
              c = { ...c, parent_id: itemId, isCreateElement: action.payload };
              const result = yield call(apiCall, c, "update");
              if (result.code == "0") {
                yield put(
                  actions.setContentRes({
                    id: itemId,
                    key: "changed",
                    elementId: c.id,
                  })
                );
              }
            }
          }
        } catch (error: any) {
          failedRequests.push({
            elementId: itemId,
            elementNo: c?.sequence_no,
            elementType: c.type,
          });
          console.error("Request failed:", error);
          yield call(handleAPIError, error);
        }
      }
    }
    if (failedRequests.length == 0 && !validationError) {
      const route: IAPICallConfig = {
        method: "POST",
        isSecureRoute: true,
        route: "",
      };
      switch (action.payload.element_name) {
        case "Topic":
          route["route"] = routes.topic.delete;
          route["body"] = {
            topic_id: action.payload.element_id,
          };
          break;
        case "Lesson":
          route["route"] = routes.lesson.delete;
          route["body"] = {
            lesson_id: action.payload.element_id,
          };

          break;
        case "Point":
          route["route"] = routes.point.delete;
          route["body"] = {
            point_id: action.payload.element_id,
          };

          break;
        case "Sub-Point":
          route["route"] = routes.subPoint.delete;
          route["body"] = {
            sub_point_id: action.payload.element_id,
          };
          break;
      }
      const response: any = yield call(makeCall, {
        ...route,
        body: {
          ...route["body"],
          language: action.payload?.language || ILanguage.en,
        },
      });
      if (response.code == "0") {
        try {
          const getModule = yield call(makeCall, {
            method: "POST",
            isSecureRoute: true,
            route: routes.module.get,
            body: {
              module_id: module.result.id,
            },
            isLockModule: true,
          });
          if (getModule.module) {
            if (!Object.keys(getModule.module?.topics || {})?.length)
              yield put(actions.setIsEditorEmpty(true));
            yield put(actions.setModuleState(getModule.module));
            yield put(
              defaultLayoutActions.setAppMessage({
                currentMessage: DEFAULT_DESCRIPTION.Success,
                currentTitle: DEFAULT_TITLE.Success,
                currentVariant: VARIANTS.SUCCESS as TypeOptions,
                count: uuidv4(),
              })
            );
          }
        } catch (error: any) {
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Error,
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidv4(),
          });
          yield put(actions.setIsElementDeleting(true));
          yield put(actions.setIsDeletingParentElement(false));
          yield call(handleAPIError, error);
        }
      }
    } else {
      if (validationError) {
        yield put(actions.setIsValidationOpen(true));
      } else if (failedRequests.length) {
        yield put(
          actions.manageFailed({
            module: module.result,
            errors: failedRequests,
          })
        );
      }
    }
    yield put(actions.setIsModuleLoading(false));
  } catch (error: any) {
    defaultLayoutActions.setAppMessage({
      currentMessage: DEFAULT_DESCRIPTION.Error,
      currentTitle: DEFAULT_TITLE.Fail,
      currentVariant: VARIANTS.Error as TypeOptions,
      count: uuidv4(),
    });
    yield put(actions.setIsElementDeleting(true));
    yield put(actions.setIsDeletingParentElement(false));
    yield put(actions.setIsModuleLoading(false));
    yield call(handleAPIError, error);
  }
}

function* fileUploadProgressWatcher(channel: any) {
  while (true) {
    const progress: number = yield take(channel);
    if (!Number.isNaN(progress) && typeof progress === "number") {
      yield put(
        actions.updateFileUploadState({
          progress,
          processing: true,
          haveError: false,
        })
      );
    }
  }
}

function* setUploadFileSaga(
  action: PayloadAction<{
    fileName: string;
    fileType: string;
    fileSize: number;
    id: string;
    isImage: boolean;
  }>
) {
  try {
    const { fileName, fileSize, fileType, id, isImage } = action.payload;
    const file = yield select(selectSelectedFile);
    if (!file) {
      yield put(
        actions.updateFileUploadState({ haveError: true, processing: false })
      );
      return;
    }

    const data = new FormData();
    if (isImage) data.append("image_file", file);
    else data.append("ppt_file", file);

    yield put(
      actions.updateFileUploadState({
        progress: 0,
        processing: true,
        haveError: false,
      })
    );

    const [uploadPromise, channel] = yield call(createFileUploaderChannel, {
      data,
      id,
      isImage,
    });

    yield fork(fileUploadProgressWatcher, channel);

    const response = yield call(() => uploadPromise);

    if (response && response.code === "0") {
      yield put(
        actions.updateFileUploadState({
          progress: 100,
          processing: false,
          haveError: false,
        })
      );
      yield put(
        actions.finishUploadState({
          isImage,
          id:
            action.payload.id.charAt(0) == "T" ? action.payload.id : undefined,
          uploadedPath: isImage ? response.image_url : response.ppt_url,
        })
      );
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    } else {
      throw new Error("Upload failed");
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updateFileUploadState({
        progress: 0,
        processing: false,
        haveError: true,
      })
    );
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: "Upload failed! Please try again.",
        currentTitle: "Fail",
        currentVariant: "error",
        count: uuidv4(),
      })
    );
  }
}

function* downloadFileSaga(
  action: PayloadAction<{
    itemId: string;
    itemType: "isImage" | "isPdf" | "isPpt";
    isCourseDetails?: boolean;
  }>
) {
  try {
    let route = "";
    let body = {};
    let response: any;
    if (action.payload.itemType == "isImage") {
      yield put(defaultLayoutActions.setDownloadingStatus("processing"));
      route = routes.module.image;
      body["id_list"] = [action.payload.itemId];
      response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route,
        body,
      });
    } else if (
      action.payload.itemType == "isPpt" ||
      action.payload.itemType == "isPdf"
    ) {
      if (action.payload.itemType == "isPpt") {
        route = routes.module.ppt;
        body["id"] = action.payload.itemId;
      } else if (action.payload.itemType == "isPdf") {
        route = routes.module.pdf;
        body["module_id"] = action.payload.itemId;
      }
      response = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route,
        body,
        isLockModule: true,
      });
    }
    if (response?.code == "0") {
      yield put(defaultLayoutActions.setDownloadingStatus("success"));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    } else {
      yield put(defaultLayoutActions.setDownloadingStatus("error"));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Error,
          currentTitle: DEFAULT_TITLE.Fail,
          currentVariant: VARIANTS.Error as TypeOptions,
          count: uuidv4(),
        })
      );
    }
    yield put(actions.setIsDownloadingFile(false));
    yield put(actions.setIsModuleLoading(false));
  } catch (error: any) {
    if (
      !action.payload?.isCourseDetails &&
      (action.payload.itemType == "isPdf" ||
        (action.payload.itemId.charAt(0) !== "T" &&
          action.payload.itemType == "isPpt"))
    ) {
      const module = yield select(selectModule);
      const errRes = JSON.parse(`${error.message}`);
      if (errRes) {
        if (errRes.status == 401) {
          yield call(handleAPIError, error);
        }
        const getModule = yield call(makeCall, {
          method: "POST",
          isSecureRoute: true,
          route: routes.module.get,
          body: {
            module_id: module.result.id,
          },
        });
        yield put(actions.setModuleState(getModule.module));
        if (getModule.module) {
          const items = yield call(parseLockRes, {
            response: errRes,
            data: getModule,
          });
          yield put(actions.setLockErrRes(items));
        }
        yield put(defaultLayoutActions.setDownloadingStatus(undefined));
      }
    } else {
      yield put(defaultLayoutActions.setDownloadingStatus("error"));
    }
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsModuleLoading(false));
    yield put(actions.setIsDownloadingFile(false));
    yield call(handleAPIError, error);
    console.log("Error Downloading Module", error);
  }
}

function* bulkDownloadFileSaga() {
  try {
    yield put(defaultLayoutActions.setDownloadingStatus("processing"));
    const module = yield select(selectModule);
    const elementIds = yield call(
      findElementsWithMissingImageUrl,
      module.result
    );
    let route = "";
    let body = {};
    let response: any;
    route = routes.module.image;
    body["id_list"] = [...elementIds];
    response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route,
      body,
    });
    yield put(defaultLayoutActions.setDownloadingStatus("success"));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Success,
        currentTitle: DEFAULT_TITLE.Success,
        currentVariant: VARIANTS.SUCCESS as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsDownloadingFile(false));
    yield put(actions.setIsModuleLoading(false));
  } catch (error: any) {
    yield put(defaultLayoutActions.setDownloadingStatus("error"));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsModuleLoading(false));
    yield put(actions.setIsDownloadingFile(false));
    yield call(handleAPIError, error);
    console.log("Error Downloading Module", error);
  }
}

function* downloadPPTSaga(action: PayloadAction<string>) {
  try {
    const response = yield call(makeCall, {
      method: "GET",
      route: routes.fileRelated.downloadPPT,
      query: {
        id: action.payload,
      },
      isOnDownload: true,
      isSecureRoute: true,
      defaultFileName: `${action.payload}.pptx`,
    });

    if (response.success) {
      yield put(actions.setIsDownloadPPT(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    } else {
      throw new Error("Download failed");
    }
  } catch (error) {
    console.error("Download error:", error);
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
  }
}
function* getCommentsSaga() {
  try {
    const module = yield select(selectModule);
    const activeCommentActiveId = yield select(selectActiveCommentBlockId);

    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.module.get,
      body: {
        module_id: module.result.id,
      },
    });

    if (response.module) {
      const comments = yield call(
        extractCommentsFromModule,
        response.module,
        activeCommentActiveId
      );
      yield put(actions.setComments(comments));
    }
    yield put(actions.setIsCommentLoading(false));
  } catch (error) {
    yield put(actions.setIsCommentLoading(false));
    yield call(handleAPIError, error);
  }
}

function* createCommentSaga(action: ReturnType<typeof actions.createComment>) {
  try {
    const module = yield select(selectModule);

    const { blockId, content } = action.payload;

    const findContentId = (obj: any): string | null => {
      if (typeof obj !== "object" || obj === null) {
        return null;
      }

      if (obj.contents && obj.contents[blockId]) {
        const firstContent = obj.contents[blockId].contents[0];
        return firstContent.id;
      }

      for (const key in obj) {
        const result = findContentId(obj[key]);
        if (result) return result;
      }
      return null;
    };

    const contentId = findContentId(module);

    if (!contentId) {
      throw new Error("Content ID not found");
    }

    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.comments.create,
      body: {
        parent_id: contentId,
        content: content,
      },
    });

    if (response.code === "0") {
      // Fetch updated module data
      const updatedModuleResponse = yield call(makeCall, {
        method: "POST",
        isSecureRoute: true,
        route: routes.module.get,
        body: {
          module_id: module.result.id,
        },
      });

      if (updatedModuleResponse.module) {
        const comments = yield call(
          extractCommentsFromModule,
          updatedModuleResponse.module
        );
        yield put(actions.setComments(comments));
        yield put(
          defaultLayoutActions.setAppMessage({
            currentMessage: DEFAULT_DESCRIPTION.Success,
            currentTitle: DEFAULT_TITLE.Success,
            currentVariant: VARIANTS.SUCCESS as TypeOptions,
            count: uuidv4(),
          })
        );
      } else {
        throw new Error("Failed to fetch updated module data");
      }
    } else {
      throw new Error("Failed to create comment");
    }
    yield put(actions.setIsCommentLoading(false));
  } catch (error) {
    yield put(actions.setIsCommentLoading(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield call(handleAPIError, error);
  }
}

function* updateCommentSaga(action: ReturnType<typeof actions.updateComment>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.comments.update,
      body: {
        comment_id: action.payload.id,
        content: action.payload.content,
      },
    });

    if (response.code === "0") {
      yield put(
        actions.updateCommentSuccess({
          id: action.payload.id,
          content: action.payload.content,
        })
      );
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    }
    yield put(actions.setIsCommentLoading(false));
  } catch (error) {
    yield put(actions.setIsCommentLoading(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield call(handleAPIError, error);
  }
}

function* deleteCommentSaga(action: ReturnType<typeof actions.deleteComment>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.comments.delete,
      body: {
        comment_id: action.payload,
      },
    });

    if (response.code === "0") {
      yield put(actions.deleteCommentSuccess(action.payload));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    }
    yield put(actions.setIsCommentLoading(false));
  } catch (error) {
    yield put(actions.setIsCommentLoading(false));
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield call(handleAPIError, error);
  }
}

export function* moduleSaga() {
  yield takeLatest(actions.getModule.type, getModuleSaga);
  yield takeLatest(actions.createElement.type, createElementSaga);
  yield takeLatest(actions.updateElement.type, updateElementSaga);
  yield takeLatest(actions.deleteElement.type, deleteElementSaga);
  yield takeLatest(actions.downloadFile.type, downloadFileSaga);
  yield takeLatest(actions.bulkDownloadFile.type, bulkDownloadFileSaga);
  yield takeLatest(actions.manageContent.type, manageContentSaga);
  yield takeLatest(actions.lockModule.type, lockModuleSaga);
  yield takeLatest(actions.translateLanguage.type, translateLanguageSaga);
  yield takeLatest(actions.uploadFileStart.type, setUploadFileSaga);
  yield takeLatest(actions.downloadPPT.type, downloadPPTSaga);
  yield takeLatest(actions.getComments.type, getCommentsSaga);
  yield takeLatest(actions.updateComment.type, updateCommentSaga);
  yield takeLatest(actions.deleteComment.type, deleteCommentSaga);
  yield takeLatest(actions.createComment.type, createCommentSaga);
}

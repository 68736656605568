import { PayloadAction } from "@reduxjs/toolkit";
import { IUserModel } from "app/Model/user";
import { IForgetPassword, ISignInFormValues } from "app/pages/SignInPage/types";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { createSlice } from "utils/@reduxjs/toolkit";
import { rootSaga } from "./saga";
import { IDefaultLayoutState, IToastMessageConfig } from "./type";
import { ILanguage } from "utils/type";
import { getFromLocalStorage } from "utils/localStorage";

export const initialState: IDefaultLayoutState = {
  appMessage: undefined,
  isSigningIn: false,
  isLoadingForgetPassword: false,
  isLoginError: false,
  user: undefined,
  styleProps: {
    isFirstEditor: false,
  },
  isLoginModalOpen: false,
  isImportModleDisabled: false,
  downloadingItemsStatus: undefined,
  lang: undefined,
};
const defaultLayoutSlice = createSlice({
  name: "defaultLayoutState",
  initialState,
  reducers: {
    setIsCourseCreating(state, action: PayloadAction<boolean | string>) {
      state.isCourseCreating = action.payload;
    },
    setIsModuleCreating(state, action: PayloadAction<boolean | string>) {
      state.isModuleCreating = action.payload;
    },
    setAppMessage(state, action: PayloadAction<IToastMessageConfig>) {
      state.appMessage = action.payload;
    },
    hideAppMessage(state) {
      state.appMessage = undefined;
    },
    setStyleProps(state, action: PayloadAction<boolean>) {
      state.styleProps.isFirstEditor = action.payload;
    },
    forgetPassword(state, action: PayloadAction<IForgetPassword>) {
      state.isLoadingForgetPassword = true;
    },
    setIsForgetPasswordLoading(state, action: PayloadAction<boolean>) {
      state.isLoadingForgetPassword = action.payload;
    },
    setDownloadingStatus(
      state,
      action?: PayloadAction<"processing" | "success" | "error" | undefined>
    ) {
      state.downloadingItemsStatus = action?.payload;
    },
    signinUser(state, action: PayloadAction<ISignInFormValues>) {
      state.isSigningIn = true;
    },
    getUserFromToken(state) {
      state.isSigningIn = true;
    },
    setGlobalLanguage(state, action: PayloadAction<ILanguage>) {
      state.lang = action.payload;
    },
    setIsSigningIn(state, action: PayloadAction<boolean>) {
      state.isSigningIn = action.payload;
    },
    setIsLoginError(state, action: PayloadAction<boolean>) {
      state.isLoginError = action.payload;
    },
    setIsLoginModalOpen(state, action: PayloadAction<boolean>) {
      state.isLoginModalOpen = action.payload;
    },
    setIsImportModuleDisabeled(state, action: PayloadAction<boolean>) {
      state.isImportModleDisabled = action.payload;
    },
    setUser(state, action: PayloadAction<IUserModel>) {
      state.user = action.payload;
      state.isSigningIn = false;
    },
    clearUser(state) {
      state.user = undefined;
    },
    updateUserProfilePicture(state, action: PayloadAction<string>) {
      if (state.user) state.user.image_url = action.payload;
    },
  },
});

export const { actions: defaultLayoutActions } = defaultLayoutSlice;

export const useDefaultLayoutSlice = () => {
  useInjectReducer({
    key: defaultLayoutSlice.name,
    reducer: defaultLayoutSlice.reducer,
  });
  useInjectSaga({ key: defaultLayoutSlice.name, saga: rootSaga });
  return { actions: defaultLayoutSlice.actions };
};

import * as yup from "yup";

interface Block {
  id: string;
  type: string;
  data: {
    text?: string;
    items?: string[];
  };
  sequence_no: number;
}

interface ValidationResult {
  success: boolean;
  errors: Array<{
    blockId: string;
    path: string;
    message: string;
  }>;
}

const parseObjectForValidation = (blocks: Block[]) => {
  return blocks.reduce(
    (acc: { paragraphs: Block[]; lists: Block[] }, block) => {
      if (block?.data) {
        if (block.type === "paragraph") {
          acc.paragraphs.push(block);
        } else if (block.type === "list") {
          acc.lists.push(block);
        }
      }
      return acc;
    },
    { paragraphs: [], lists: [] }
  );
};

const notJustNumbers = (value: string | undefined): boolean => {
  return value !== undefined && !/^\d+$/.test(value);
};

const notJustSymbols = (value: string | undefined): boolean => {
  return value !== undefined && /[a-zA-Z0-9]/.test(value);
};

export async function validateObject(
  objectToValidate: Block[]
): Promise<ValidationResult> {
  const result: ValidationResult = { success: true, errors: [] };
  const parsedObj = parseObjectForValidation(objectToValidate);

  const schema = yup.object({
    paragraphs: yup.array().of(
      yup.object({
        data: yup.object({
          text: yup
            .string()
            .min(4, "Paragraph must be at least 4 characters")
            .test(
              "notJustNumbers",
              "Paragraph cannot be just numbers",
              notJustNumbers
            )
            .test(
              "notJustSymbols",
              "Paragraph cannot contain only symbols",
              notJustSymbols
            )
            .required("Paragraph cannot be empty"),
        }),
      })
    ),
    lists: yup.array().of(
      yup.object({
        data: yup.object({
          items: yup
            .array()
            .of(
              yup
                .string()
                .min(4, "List Item must be at least 4 characters")
                .test(
                  "notJustNumbers",
                  "List item cannot be just numbers",
                  notJustNumbers
                )
                .test(
                  "notJustSymbols",
                  "Paragraph cannot contain only symbols",
                  notJustSymbols
                )
                .required("List item cannot be empty")
            ),
        }),
      })
    ),
  });

  try {
    await schema.validate(parsedObj, { abortEarly: false });
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      const errorMap: { [blockId: string]: string[] } = {};

      error.inner.forEach((err) => {
        if (err.path) {
          const pathParts = err.path.split(".");
          const typeAndIndex = pathParts[0].match(/(\w+)\[(\d+)\]/);

          if (typeAndIndex) {
            const [, type, indexStr] = typeAndIndex;
            const index = parseInt(indexStr);

            if ((type === "paragraphs" || type === "lists") && !isNaN(index)) {
              const block = parsedObj[type][index];
              if (block) {
                if (!errorMap[block.id]) {
                  errorMap[block.id] = [];
                }
                errorMap[block.id].push(err.message);
              }
            }
          }
        }
      });

      for (const [blockId, messages] of Object.entries(errorMap)) {
        result.errors.push({
          blockId,
          path: "",
          message: messages.join(", "),
        });
      }

      result.success = false;
    }
  }

  return result;
}

export function validateContents(moduleRes) {
  for (const topicId in moduleRes.topics) {
    const topic = moduleRes.topics[topicId];
    if (!topic.contents || Object.keys(topic.contents).length === 0) {
      return false;
    }

    // Check each lesson
    for (const lessonId in topic.lessons) {
      const lesson = topic.lessons[lessonId];
      if (!lesson.contents || Object.keys(lesson.contents).length === 0) {
        return false;
      }

      // Check each point
      for (const pointId in lesson.points) {
        const point = lesson.points[pointId];
        if (!point.contents || Object.keys(point.contents).length === 0) {
          return false;
        }

        // Check each sub-point
        for (const subPointId in point.sub_points) {
          const subPoint = point.sub_points[subPointId];
          if (
            !subPoint.contents ||
            Object.keys(subPoint.contents).length === 0
          ) {
            return false;
          }
        }
      }
    }
  }
  // All objects have contents
  return true;
}

import React, { useState, useCallback, useEffect } from "react";
import { signal, Signal } from "@preact-signals/safe-react";
import { withTrackSignals } from "@preact-signals/safe-react/manual";
import {
  Paper,
  IconButton,
  Box,
  List,
  ListItem,
  Typography,
  Avatar,
  Stack,
  TextareaAutosize,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { truncate } from "helper/helperFunctions";
import { useDispatch } from "react-redux";

export interface IComment {
  id: string;
  blockId: string;
  content: string;
  username: string;
  avatar: string;
  date: string;
}

const commentSchema = Yup.object().shape({
  content: Yup.string()
    .required("Comment is required")
    .min(3, "Comment must be at least 3 characters"),
});

interface RenderItemProps {
  blockId: string;
  commentBlockId: string | null;
  onClose: () => void;
  addCommentBlockData: (data: { id: string; count: number }) => void;
  removeBlockComments: () => void;
  setIsOpen: (isOpen: boolean) => void;
  moduleId: string;
}

const comments: Signal<IComment[]> = signal<IComment[]>([]);

const isPopupOpen: Signal<boolean> = signal<boolean>(false);
const isLoading: Signal<boolean> = signal<boolean>(false);

const findCommentsByBlockId = (module: any, targetBlockId: string): any[] => {
  const searchComments = (obj: any): any[] => {
    if (typeof obj !== "object" || obj === null) {
      return [];
    }

    if (obj.contents && obj.contents[targetBlockId]) {
      const content = obj.contents[targetBlockId];
      if (
        content.contents &&
        content.contents[0] &&
        content.contents[0].comments
      ) {
        return Object.entries(content.contents[0].comments).map(
          ([id, commentData]: [string, any]) => ({
            id,
            blockId: targetBlockId,
            content: commentData.comment,
            username: commentData.modified_by,
            avatar: "/broken_image",
            date: commentData.modified_date,
          })
        );
      }
    }

    for (const key in obj) {
      const result = searchComments(obj[key]);
      if (result.length) return result;
    }
    return [];
  };

  return searchComments(module);
};

const NewCommentInput = React.memo(
  ({ onSave }: { onSave: (content: string) => Promise<void> }) => {
    const [newCommentContent, setNewCommentContent] = useState("");
    const [newCommentError, setNewCommentError] = useState("");

    const handleSave = async () => {
      try {
        await commentSchema.validate({ content: newCommentContent });
        await onSave(newCommentContent);
        setNewCommentContent("");
        setNewCommentError("");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setNewCommentError(err.message);
        }
      }
    };

    return (
      <Box mt="auto" display="flex" flexDirection="column" alignItems="stretch">
        <TextareaAutosize
          value={newCommentContent}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setNewCommentContent(e.target.value)
          }
          placeholder="Add a comment..."
          style={{
            width: "100%",
            minHeight: "60px",
            padding: "8px",
            marginBottom: "8px",
          }}
        />
        {newCommentError && (
          <Typography color="error" variant="caption">
            {newCommentError}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleSave} color="primary">
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
);

const RenderItem: React.FC<RenderItemProps> = ({
  blockId,
  commentBlockId,
  onClose,
  addCommentBlockData,
  removeBlockComments,
  setIsOpen,
  moduleId,
}) => {
  const dispatch = useDispatch();
  const [module, setModule] = useState<any>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    const fetchModule = async () => {
      isLoading.value = true;
      try {
        const response = await makeCall({
          method: "POST",
          isSecureRoute: true,
          route: routes.module.get,
          body: {
            module_id: moduleId,
          },
        });
        if (response.module) {
          setModule(response.module);
        }
      } catch (error) {
        console.error("Error fetching module:", error);
        showSnackbar("Failed to load comments", "error");
      } finally {
        isLoading.value = false;
      }
    };

    fetchModule();
  }, [moduleId]);

  useEffect(() => {
    if (module && blockId) {
      const foundComments = findCommentsByBlockId(module, blockId);
      comments.value = foundComments.map((comment: any) => ({
        id: comment.id,
        blockId,
        content: comment.content,
        username: truncate(comment.username, 10) || "Anonymous",
        avatar: "/broken_image",
        date: comment.date,
      }));
    }
  }, [module, blockId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(".ce-toolbar")) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  const saveComment = useCallback(
    async (content: string): Promise<void> => {
      try {
        const findContentId = (obj: any): string | null => {
          if (typeof obj !== "object" || obj === null) {
            return null;
          }

          if (obj.contents && obj.contents[blockId]) {
            const firstContent = obj.contents[blockId].contents[0];
            return firstContent.id;
          }

          for (const key in obj) {
            const result = findContentId(obj[key]);
            if (result) return result;
          }
          return null;
        };

        const contentId = findContentId(module);

        if (!contentId) {
          throw new Error("Content ID not found");
        }

        const response = await makeCall({
          method: "POST",
          isSecureRoute: true,
          route: routes.comments.create,
          body: {
            parent_id: contentId,
            content: content,
          },
        });

        if (response.code === "0") {
          // Fetch updated module data
          const updatedModuleResponse = await makeCall({
            method: "POST",
            isSecureRoute: true,
            route: routes.module.get,
            body: {
              module_id: module.module_id,
            },
          });

          if (updatedModuleResponse.module) {
            const updatedComments = findCommentsByBlockId(
              updatedModuleResponse.module,
              blockId
            );
            comments.value = updatedComments;
            addCommentBlockData({
              id: commentBlockId || blockId,
              count: comments.value.length,
            });
            showSnackbar("Comment added successfully", "success");
          } else {
            throw new Error("Failed to fetch updated module data");
          }
        } else {
          showSnackbar("Failed to add comment", "error");
        }
      } catch (error) {
        console.error("Error saving comment:", error);
        showSnackbar("Failed to add comment", "error");
      }
    },
    [blockId, commentBlockId, addCommentBlockData, module]
  );

  const handleDeleteComment = async (commentId: string): Promise<void> => {
    try {
      const response = await makeCall({
        method: "POST",
        isSecureRoute: true,
        route: routes.comments.delete,
        body: {
          comment_id: commentId,
        },
      });

      if (response.code === "0") {
        comments.value = comments.value.filter(
          (comment) => comment.id !== commentId
        );
        if (comments.value.length === 0) {
          removeBlockComments();
        } else {
          addCommentBlockData({
            id: commentBlockId || blockId,
            count: comments.value.length,
          });
        }
        showSnackbar("Comment deleted successfully", "success");
      } else {
        showSnackbar("Failed to delete comment", "error");
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
      showSnackbar("Failed to delete comment", "error");
    }
  };

  const handleEditComment = async (
    commentId: string,
    newContent: string
  ): Promise<void> => {
    try {
      const response = await makeCall({
        method: "POST",
        isSecureRoute: true,
        route: routes.comments.update,
        body: {
          comment_id: commentId,
          content: newContent,
        },
      });

      if (response.code === "0") {
        comments.value = comments.value.map((comment) =>
          comment.id === commentId
            ? { ...comment, content: newContent }
            : comment
        );
        showSnackbar("Comment updated successfully", "success");
      } else {
        showSnackbar("Failed to update comment", "error");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      showSnackbar("Failed to update comment", "error");
    }
  };

  const ShowComments = withTrackSignals(() => {
    const [editingCommentId, setEditingCommentId] = useState<string | null>(
      null
    );
    const [editContent, setEditContent] = useState("");
    const [editError, setEditError] = useState("");

    if (isLoading.value) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (comments.value.length === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <Typography variant="body1" color="text.secondary">
            No comments yet. Be the first to comment!
          </Typography>
        </Box>
      );
    }

    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {comments.value.map((comment, index) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            sx={{
              flexDirection: "column",
              borderBottom: "1px solid #e0e0e0",
              py: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar src={comment.avatar} alt={comment.username} />
                <Typography variant="subtitle2">{comment.username}</Typography>
              </Stack>
              <Typography variant="caption" color="text.secondary">
                {comment.date}
              </Typography>
            </Box>
            {editingCommentId === comment.id ? (
              <Box sx={{ width: "100%" }}>
                <TextareaAutosize
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                  style={{
                    width: "100%",
                    minHeight: "60px",
                    padding: "8px",
                    marginBottom: "8px",
                  }}
                />
                {editError && (
                  <Typography color="error" variant="caption">
                    {editError}
                  </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      try {
                        await commentSchema.validate({ content: editContent });
                        await handleEditComment(comment.id, editContent);
                        setEditingCommentId(null);
                        setEditError("");
                      } catch (err) {
                        if (err instanceof Yup.ValidationError) {
                          setEditError(err.message);
                        }
                      }
                    }}
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditingCommentId(null);
                      setEditError("");
                    }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Typography variant="body2">{comment.content}</Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditingCommentId(comment.id);
                      setEditContent(comment.content);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteComment(comment.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </ListItem>
        ))}
      </List>
    );
  });

  const handleClose = (): void => {
    isPopupOpen.value = false;
    onClose();
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          top: "50%",
          right: "90px",
          transform: "translateY(-50%)",
          p: 2,
          zIndex: 1000,
          width: "400px",
          maxHeight: "80vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <ShowComments />
        <NewCommentInput onSave={saveComment} />
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RenderItem;

import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { API, BlockMutationEvent, OutputData } from "@kalebu2468/editorjs";
import { CustomModal, Flex } from "app/components/Core";
import { InView } from "react-intersection-observer";
import { IContentModel, ITopicModel } from "app/Model/course";
import { IEnhancedOutputBlockData } from "./type";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActiveCommentBlockId,
  selectActiveTabValue,
  selectInvalidBlocks,
  selectIsRightSidebarOpen,
  selectModule,
  selectUploadState,
} from "./slice/selector";
import { useModuleSlice } from "./slice";
import { useParams, useSearchParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, Drawer as MuiDrawer } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { v4 as uuidV4 } from "uuid";
import { IconButton, ListItem } from "@mui/material";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import AppBar from "app/components/Layout/AppBar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Spinner } from "assets/images";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { WarningModal } from "app/components/WarningModal";
import { Menu } from "styled-icons/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  addTimestampToUrl,
  debounce,
  findElementParents,
  findNextInvalidBlock,
  hasChanges,
  hasContentChanged,
  truncate,
  turnToHumanReadable,
} from "helper/helperFunctions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { ImageViewerModal } from "app/components/ImageViewerModal";
import Editor from "app/components/Editor";
import EditorJS from "@editorjs/editorjs";
import { UnlockWarningModal } from "app/components/UnlockModal";
import { ILanguage } from "utils/type";
import PDFViewer from "app/components/PDFViewer";
import { useGlossarySlice } from "../Glossary/slice";
import { selectGlossaries } from "../Glossary/slice/selector";
import { GlossaryModal } from "app/components/GlossaryModal";
import { CustomTabPanel, a11yProps } from "app/components/CustomTabPanel";
import {
  selectResultModalOpen,
  selectUser,
} from "../DefaultLayout/slice/selectors";
import BackupIcon from "@mui/icons-material/Backup";
import { FileUploader } from "app/components/UploadImageModal";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import { languageOptions } from "utils/constants";
import ListIcon from "@mui/icons-material/List";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { validateObject } from "app/components/Form/Validation/editorValidation";
import { IModuleComment, InvalidBlock, InvalidBlocksState } from "./slice/type";
import CommentsList from "app/components/CommentCard";
import { CustomTabModulePanel } from "app/components/CustomTabPanel/CustomTabPanelModule";

export const parseContent = (content?: Record<string, IContentModel>) => {
  if (content) {
    const sortedContents: Record<string, IContentModel> = {};
    Object.keys(content)
      .sort((a, b) => {
        return content[a].sequence_number - content[b].sequence_number;
      })
      .forEach((key) => {
        sortedContents[key] = content[key];
      });

    const parsedValue = Object.keys(sortedContents).reduce(
      (acc: IEnhancedOutputBlockData[], key) => {
        const curr = sortedContents[key];
        if (curr.type == "topic") {
          acc = [
            ...acc,
            {
              id: key,
              type: "topics",
              data: {
                text: curr.contents[0].text,
                level: 1,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        }
        if (curr.type == "lesson") {
          acc = [
            ...acc,
            {
              id: key,
              type: "lessons",
              data: {
                text: curr.contents[0].text,
                level: 2,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        }
        if (curr.type == "point") {
          acc = [
            ...acc,
            {
              id: key,
              type: "points",
              data: {
                text: curr.contents[0].text,
                level: 3,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        }
        if (curr.type == "sub_point") {
          acc = [
            ...acc,
            {
              id: key,
              type: "subpoints",
              data: {
                text: curr.contents[0].text,
                level: 4,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        }
        if (curr.type == "paragraph") {
          acc = [
            ...acc,
            {
              id: key,
              type: "paragraph",
              data: {
                text: curr.contents[0].text,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        } else if (curr.type == "list") {
          const items: Array<string> = [];
          curr.contents?.map((cont) => items.push(cont.text));
          acc = [
            ...acc,
            {
              id: key,
              type: "list",
              data: {
                style: "unordered",
                items: [...items],
              },
              sequence_no: curr.sequence_number,
            },
          ];
        } else if (curr.type == "table") {
          let maxRow = 0;
          let maxCol = 0;
          for (const item of curr["contents"]) {
            maxRow = Math.max(maxRow, item["row_number"] || 0);
            maxCol = Math.max(maxCol, item["column_number"] || 0);
          }
          const table = Array.from({ length: maxRow }, () =>
            Array(maxCol).fill("")
          );
          for (const item of curr["contents"]) {
            if (item["row_number"] && item["column_number"]) {
              const row = item?.["row_number"] - 1;
              const col = item?.["column_number"] - 1;
              table[row][col] = item["text"];
            }
          }
          acc = [
            ...acc,
            {
              id: key,
              type: "table",
              data: {
                withHeadings: false,
                content: table,
              },
              sequence_no: curr.sequence_number,
            },
          ];
        }
        return acc;
      },
      []
    );

    const _outputValue = {
      time: new Date().getTime(),
      blocks: parsedValue,
    };
    return _outputValue;
  }
};

export default function Workplace() {
  const navigate = useNavigate();
  const isUndoRedoInProgressRef = React.useRef(false);
  const undoRedoTimeoutRef = React.useRef<any | null>(null);
  const selectedRefs = React.useRef<(HTMLElement | null)[]>([]);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [anchorElLanguage, setAnchorElLanguage] =
    React.useState<null | HTMLElement>(null);
  const editorRef = React.useRef<(EditorJS | null)[]>([]);
  const [isTopic, setIsTopic] = React.useState("");
  const drawerWidth = 240;
  const { id } = useParams();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [isResizing, setIsResizing] = React.useState(false);
  const [lastX, setLastX] = React.useState(0);
  const [width, setWidth] = React.useState(drawerWidth);
  const lang = searchParams.get("lang");
  const [imageViewerOpen, setImageViewerOpen] = React.useState(false);
  const [itemType, setItemType] = React.useState<"isPpt" | "isPdf" | "isImage">(
    "isImage"
  );
  const [visibleSection, setVisibleSection] = React.useState<string>();
  const changedIdx = React.useRef<number | null>(null);
  const undoRef = React.useRef<(any | null)[]>([]);
  const editorStackRef = React.useRef<number[]>([]);
  const editorStackPointerRef = React.useRef(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [truncateValue, setTruncateValue] = React.useState(25);
  const module = useSelector(selectModule);
  const dispatch = useDispatch();
  const { actions } = useModuleSlice();
  const { actions: glossaryActions } = useGlossarySlice();
  const glossaries = useSelector(selectGlossaries);

  const [editorData, setEditorData] =
    React.useState<Record<string, OutputData>>();

  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;

  const [originalData, setOriginalData] =
    React.useState<Record<string, OutputData>>();

  const [uploadImageModalOpen, setUploadImageModalOpen] = React.useState(false);
  const isGlobalDownloading = useSelector(selectResultModalOpen);
  const [file, setFile] = React.useState<File | null>(null);
  const [fileState, setFileState] = React.useState<{
    id: string;
    isImage: boolean;
  }>({ id: "", isImage: false });
  const invalidBlocks = useSelector(selectInvalidBlocks);

  const uploadState = useSelector(selectUploadState);
  const isRightSidebarOpen = useSelector(selectIsRightSidebarOpen);
  const activeTabIndexValue = useSelector(selectActiveTabValue);
  const activeCommentBlockId = useSelector(selectActiveCommentBlockId);
  const highlightedElementRef = React.useRef<HTMLElement | null>(null);
  const [canUndo, setCanUndo] = React.useState(false);
  const [canRedo, setCanRedo] = React.useState(false);

  const handleFileSelect = (selectedFile: File) => {
    setFile(selectedFile);
    dispatch(actions.setSelectedFile(selectedFile));
  };

  const handleUpload = () => {
    if (file) {
      dispatch(
        actions.uploadFileStart({
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
          id: fileState.id || "",
          isImage: fileState.isImage,
        })
      );
    }
  };

  const handleClose = () => {
    setUploadImageModalOpen(false);
    setFile(null);
    dispatch(actions.resetUploadState());
  };

  const handleOpen = ({ id, isImage }) => {
    setFileState({ id, isImage });
    setUploadImageModalOpen(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(actions.setTabValue(newValue));
  };

  React.useEffect(() => {
    if (uploadState?.progress === 100) {
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [uploadState?.progress]);

  React.useEffect(() => {
    if (isRightSidebarOpen && activeTabIndexValue == 0) {
      dispatch(actions.getComments(activeCommentBlockId));
    }
  }, [isRightSidebarOpen, activeCommentBlockId]);

  React.useEffect(() => {
    if (activeCommentBlockId) {
      handleCommentClick(undefined, activeCommentBlockId);
    }
  }, [activeCommentBlockId]);

  React.useEffect(() => {
    Object.keys(invalidBlocks.editors).forEach((editorId) => {
      highlightInvalidBlocks(editorId, invalidBlocks);
    });
  }, [invalidBlocks]);

  React.useEffect(() => {
    dispatch(actions.getModule({ id: id!, lang }));
    return () => {
      changedIdx.current = null;
      dispatch(actions.clearData());
    };
  }, [id]);

  React.useEffect(() => {
    if (module.hierarchyError || module.isEditorEmpty) {
      setEditorData({ [uuidV4()]: { blocks: [] } });
      dispatch(actions.clearContent());
    }
    if (module.isEditorEmpty) {
      setOriginalData({});
    }
  }, [module.hierarchyError, module.isEditorEmpty]);

  const handleInitialize = React.useCallback(
    (index, instance: EditorJS, undo: any) => {
      editorRef.current[index] = instance;
      undoRef.current[index] = undo;
    },
    []
  );

  const setUndoRedoInProgress = (inProgress) => {
    isUndoRedoInProgressRef.current = inProgress;
    if (inProgress) {
      if (undoRedoTimeoutRef.current) {
        clearTimeout(undoRedoTimeoutRef.current);
      }
      undoRedoTimeoutRef.current = setTimeout(() => {
        isUndoRedoInProgressRef.current = false;
      }, 700);
    }
  };
  const updateUndoRedoState = React.useCallback(() => {
    const currentEditorIndex =
      editorStackRef.current[editorStackPointerRef.current];
    const undoInstance = undoRef.current[currentEditorIndex];
    if (undoInstance) {
      setCanUndo(undoInstance.isUndoAvailable());
      setCanRedo(undoInstance.isRedoAvailable());
    }
  }, []);

  const handleUndo = React.useCallback(() => {
    setUndoRedoInProgress(true);
    const currentEditorIndex =
      editorStackRef.current[editorStackPointerRef.current];
    const undoInstance = undoRef.current[currentEditorIndex];

    if (undoInstance && undoInstance.canUndo()) {
      undoInstance.performUndo();
      updateUndoRedoState();
    } else {
      // Move to the next editor if current editor can't undo
      if (editorStackPointerRef.current < editorStackRef.current.length - 1) {
        editorStackPointerRef.current++;
        updateUndoRedoState();
      }
    }
  }, [updateUndoRedoState]);

  const handleRedo = React.useCallback(() => {
    setUndoRedoInProgress(true);
    const currentEditorIndex =
      editorStackRef.current[editorStackPointerRef.current];
    const undoInstance = undoRef.current[currentEditorIndex];

    if (undoInstance && undoInstance.canRedo()) {
      undoInstance.performRedo();
      updateUndoRedoState();
    } else {
      // Move to the previous editor if current editor can't redo
      if (editorStackPointerRef.current > 0) {
        editorStackPointerRef.current--;
        updateUndoRedoState();
      }
    }
  }, [updateUndoRedoState]);

  const handleEditorChange = async (
    index: number,
    api: API,
    event: BlockMutationEvent | BlockMutationEvent[]
  ) => {
    const data = await api.saver.save(true);
    changedIdx.current = index;
    setEditorData((prevState) => {
      const _prev = { ...prevState };
      let _key: string = Object.keys(_prev)[index];
      if (!_key) {
        _key = data.blocks[0]?.id || uuidV4();
      }
      return { ..._prev, [_key]: data };
    });
  };

  const setInView = debounce((inView, entry) => {
    if (inView) {
      const item = entry.target.getAttribute("id").split("-")[1];
      setVisibleSection(item);
    }
  }, 300);

  React.useEffect(() => {
    if (changedIdx !== null && !module.isContentLoading) {
      findDiff();
      updateUndoRedoState();
    }
    // return () => {
    //when deleting an item editorjs throws this error https://github.com/codex-team/editor.js/issues/1503
    // editorRef.current?.map(async (editor) => {
    //   if (editor?.destroy && typeof editor.destroy == "function") {
    //     await editor.destroy();
    //   }
    // });
    // };
  }, [editorData]);

  const handleMouseMove = (e) => {
    if (isResizing) {
      const diffX = e.clientX - lastX;
      let newWidth = width + diffX;
      setTruncateValue(40);
      if (newWidth <= 240) setTruncateValue(25);
      if (newWidth < 240) {
        newWidth = 240;
      } else if (newWidth > 450) {
        newWidth = 450;
      }
      setWidth(newWidth);
      setLastX(e.clientX);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    if (!module.creatingParentElements && !module.deletingParentElements) {
      if (
        changedIdx.current != null &&
        editorData &&
        Object.keys(editorData)?.length
      ) {
        let _editorDataKey = Object.keys(editorData || {})[
          changedIdx.current + 1
        ];
        handleItemClick(_editorDataKey);
      }
    }
  }, [module.creatingParentElements, module.deletingParentElements]);

  React.useEffect(() => {
    if (
      Object.keys(module.result?.topics || {})?.length &&
      module.result?.topics &&
      module.elementSequenceUpdated
    ) {
      const parsedResult = parseContents(module.result?.topics);
      if (parsedResult) {
        setEditorData({});
        setOriginalData({});
        setOriginalData(parsedResult);
        setEditorData(parsedResult);
      }
      dispatch(actions.setIsElementSequenceUpdate(false));
    }
  }, [module.elementSequenceUpdated]);

  React.useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  // https://github.com/Jungwoo-An/react-editor-js/issues/190#issuecomment-1103949821
  React.useEffect(() => {
    let timeoutId;
    if (
      Object.keys(module.result?.topics || {})?.length &&
      module.result?.topics &&
      !module.isModuleLoading &&
      !module.isValidationModalOpen &&
      !module.isContentFailedModalOpen &&
      !module.isContentLoading
    ) {
      const parsedResult = parseContents(module.result?.topics);
      if (parsedResult) {
        timeoutId = setTimeout(() => {
          if (module.isElementAdding) {
            let _prevEditorData = { ...editorData };
            if (
              changedIdx.current != null &&
              editorData &&
              Object.keys(editorData)?.length
            ) {
              const _editorDataKey = Object.keys(editorData || {})[
                changedIdx.current
              ];
              const _data = { ..._prevEditorData[_editorDataKey] };

              // Remove additional structural elements from blocks
              const filteredBlocks = _data.blocks.filter((block, index) => {
                if (index === 0) return true; // Keep the first structural element
                return !["topics", "lessons", "points", "subpoints"].includes(
                  block.type
                );
              });

              _prevEditorData[_editorDataKey].blocks = filteredBlocks;

              for (const key in parsedResult) {
                if (!(key in _prevEditorData)) {
                  const keyValueArray = Object.entries(_prevEditorData);
                  const _cpyOriginalData = { ...originalData };
                  const originalKeyValueArray =
                    Object.entries(_cpyOriginalData);

                  // Check if this is the first element being added to an empty editor
                  if (
                    Object.values(parsedResult || {})?.length &&
                    Object.values(parsedResult || {})?.length == 1 &&
                    Object.values(parsedResult)[0].blocks.length == 1
                  ) {
                    setEditorData({});
                    setOriginalData({});
                    setEditorData(parsedResult);
                    setOriginalData(parsedResult);
                    break; // Exit the loop after setting the first topic
                  }

                  const parentElements = findElementParents(
                    _editorDataKey,
                    module.result || {}
                  );
                  let parentId: string;
                  let insertIndex: number;

                  switch (key[0]) {
                    case "T":
                      parentId = module.result?.id!;
                      insertIndex = Object.keys(
                        module.result?.topics || {}
                      ).length;
                      break;
                    case "L":
                      parentId = parentElements!.find((id) =>
                        id.startsWith("T")
                      );
                      insertIndex = _editorDataKey.startsWith("T")
                        ? 0
                        : module.result?.topics?.[parentId]?.lessons[
                            _editorDataKey
                          ].sequence_number || 0;
                      break;
                    case "P":
                      parentId =
                        parentElements!.find((id) => id.startsWith("L")) ||
                        _editorDataKey;
                      insertIndex = _editorDataKey.startsWith("L")
                        ? 0
                        : module.result?.topics?.[
                            parentElements!.find((id) => id.startsWith("T"))
                          ]?.lessons?.[parentId]?.points[_editorDataKey]
                            .sequence_number || 0;
                      break;
                    case "S":
                      parentId =
                        parentElements!.find((id) => id.startsWith("P")) ||
                        _editorDataKey;
                      insertIndex = _editorDataKey.startsWith("P")
                        ? 0
                        : module.result?.topics?.[
                            parentElements!.find((id) => id.startsWith("T"))
                          ]?.lessons?.[
                            parentElements!.find((id) => id.startsWith("L"))
                          ]?.points?.[parentId]?.sub_points[_editorDataKey]
                            .sequence_number || 0;
                      break;
                    default:
                      parentId = module.result?.id!;
                      insertIndex = 0;
                  }

                  const insertPosition = keyValueArray.findIndex(
                    ([k]) => k === parentId
                  );
                  if (insertPosition !== -1) {
                    keyValueArray.splice(insertPosition + insertIndex + 1, 0, [
                      key,
                      parsedResult[key],
                    ]);
                    originalKeyValueArray.splice(
                      insertPosition + insertIndex + 1,
                      0,
                      [key, parsedResult[key]]
                    );
                  } else {
                    keyValueArray.push([key, parsedResult[key]]);
                    originalKeyValueArray.push([key, parsedResult[key]]);
                  }

                  const modifiedObj = Object.fromEntries(keyValueArray);
                  const originalModifiedObj = Object.fromEntries(
                    originalKeyValueArray
                  );
                  setEditorData({});
                  setOriginalData({});
                  setOriginalData(originalModifiedObj);
                  setEditorData(modifiedObj);
                }
              }
            }
            dispatch(actions.setIsElementAdding(false));
            dispatch(actions.setIsCreatingParentElement(false));
          } else if (module.isElementDeleting) {
            let _prevEditorData = { ...editorData };
            if (
              changedIdx.current != null &&
              editorData &&
              Object.keys(editorData)?.length
            ) {
              const _editorDataKey = Object.keys(editorData || {})[
                changedIdx.current
              ];
              const _data = { ..._prevEditorData[_editorDataKey] };
              let count = 0;
              _data?.blocks.map((block) => {
                if (
                  ["topics", "lessons", "points", "subpoints"].includes(
                    block.type
                  )
                ) {
                  count = count + 1;
                }
                if (count > 1) {
                  const _blocks = _prevEditorData[_editorDataKey].blocks.filter(
                    (_block) => _block.id != block.id
                  );
                  _prevEditorData = {
                    ..._prevEditorData,
                    [_editorDataKey]: {
                      ..._prevEditorData[_editorDataKey],
                      blocks: _blocks,
                    },
                  };
                  count = 0;
                  return;
                }
              });
              for (const key in _prevEditorData) {
                if (!(key in parsedResult)) {
                  const keyValueArray = Object.entries(_prevEditorData);
                  const _cpyOriginalData = { ...originalData };
                  if (keyValueArray.length == 1) {
                    setOriginalData({});
                    setEditorData({});
                    setEditorData(parsedResult);
                    setOriginalData(parsedResult);
                  } else {
                    delete _prevEditorData[key];
                    delete _cpyOriginalData[key];
                    setEditorData({});
                    setOriginalData({});
                    setOriginalData(_cpyOriginalData);
                    setEditorData(_prevEditorData);
                  }
                }
              }
            }
            dispatch(actions.setIsElementDeleting(false));
          } else {
            setEditorData({});
            setOriginalData({});
            setOriginalData(parsedResult);
            setEditorData(parsedResult);
          }
          if (module.deletingParentElements) {
            dispatch(actions.setIsDeletingParentElement(false));
          }
        }, 2000); // 2-second delay
      }
    }
    return () => clearTimeout(timeoutId); // Clear timeout on unmount or dependency change
  }, [module.isModuleLoading, module.isContentLoading, module.language]);

  React.useEffect(() => {
    if (editorRef && editorData) {
      Object.keys(editorData)?.map(async (editorKey, index) => {
        const editorDiv = document.querySelector(`#editor-${editorKey}`);
        if (editorDiv) {
          const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === "childList") {
                const codexEditor = editorDiv.querySelector(
                  ".codex-editor"
                ) as HTMLElement;
                if (codexEditor) {
                  const zIndexValue = Object.keys(editorData).length - index;
                  codexEditor.style.zIndex = zIndexValue.toString();
                  observer.disconnect();
                }
              }
            });
          });
          observer.observe(editorDiv, { childList: true, subtree: true });
          setTimeout(() => {
            observer.disconnect();
          }, 5000);
        }
      });
    }
  }, [Object.values(editorData || {}).length || role]);

  const handleSelectionChange = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = module.targetModuleGlossary.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    const filteredIds = selectedRowData.map((item) => item.id);
    dispatch(actions.setSelectedGlossaryIds(filteredIds));
  };

  const handleItemClick = (id) => {
    const _idx = Object.keys(editorData || {}).findIndex((key) => key == id);
    if (selectedRefs.current[_idx]) {
      selectedRefs?.current[_idx]?.scrollIntoView({ behavior: "smooth" });
      setVisibleSection(id);
    }
  };

  const handleCommentClick = (
    comment?: IModuleComment,
    isActiveCommentBlockId?: string
  ) => {
    let element;
    if (isActiveCommentBlockId) {
      element = document.querySelector(
        `[data-id="${isActiveCommentBlockId}"]`
      ) as HTMLElement | null;
    } else if (comment) {
      element = document.querySelector(
        `[data-id="${comment.parentBlockId}"]`
      ) as HTMLElement | null;
    }

    highlightElement(element, isActiveCommentBlockId);
  };

  const highlightElement = (
    element: HTMLElement | null,
    isActiveCommentBlockId?: string
  ) => {
    if (element) {
      if (!isActiveCommentBlockId) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      // Remove highlight from previously highlighted element
      if (
        highlightedElementRef.current &&
        highlightedElementRef.current !== element
      ) {
        highlightedElementRef.current.style.backgroundColor = "";
        const prevParentBlock = highlightedElementRef.current.closest(
          ".ce-block"
        ) as HTMLElement | null;
        if (prevParentBlock) {
          prevParentBlock.style.backgroundColor = "";
        }
      }

      // Highlight new element
      element.style.backgroundColor = "#e1f2ff";
      highlightedElementRef.current = element;

      if (element.classList.contains("ce-paragraph")) {
        const parentBlock = element.closest(".ce-block") as HTMLElement | null;
        if (parentBlock) {
          parentBlock.style.backgroundColor = "#e1f2ff";
        }
      }
    }
  };

  const handleCloseRightSidebar = () => {
    dispatch(actions.setRightSidebarOpen(false));
    dispatch(actions.setActiveCommentBlockId(null));
    // Remove highlight when sidebar is closed
    if (highlightedElementRef.current) {
      highlightedElementRef.current.style.backgroundColor = "";
      const parentBlock = highlightedElementRef.current.closest(
        ".ce-block"
      ) as HTMLElement | null;
      if (parentBlock) {
        parentBlock.style.backgroundColor = "";
      }
      highlightedElementRef.current = null;
    }
  };

  const handleWarningModalClose = () => {
    if (module.isContentFailedModalOpen)
      dispatch(actions.setIsContentErrorModalOpen(false));
    if (module.isValidationModalOpen)
      dispatch(actions.setIsValidationOpen(false));
    if (module.lockErrorWarningModal)
      dispatch(actions.setIsLockModalOpen(false));
    if (module.imageGenerateWarningModal)
      dispatch(actions.setIsImageGenerateErrorModalOpen(false));
    if (module.hierarchyError) dispatch(actions.setIsHierarchyError(false));
    if (module.isHasChangeModal) dispatch(actions.setIsHasChangesModal(""));
    if (module?.isUpdatingElementSequenceModal) {
      dispatch(actions.setIsUpdatingElementSequenceModal(undefined));
    }
  };

  const handleDeleteElement = (_el: {
    elementName: string;
    elementId: string;
  }) => {
    dispatch(
      actions.deleteElement({
        element_name: _el.elementName,
        element_id: _el.elementId,
      })
    );
  };

  const handleMouseDown = (e) => {
    setIsResizing(true);
    setLastX(e.clientX);
  };

  const handleGetElement = (id) => {
    const firstLetter = id[0];
    let elType;
    switch (firstLetter) {
      case "L":
        elType = "lessons";
        break;
      case "P":
        elType = "points";
        break;
      case "S":
        elType = "subpoints";
        break;
      default:
        elType = "";
    }
    return elType;
  };

  const highlightInvalidBlocks = (
    editorId: string,
    invalidBlocks: InvalidBlocksState
  ) => {
    const editor = document.querySelector(`#editor-${editorId}`);
    if (!editor) return;

    // Remove existing highlighting and error messages
    editor
      .querySelectorAll(".invalid-block-highlight, .invalid-block-error")
      .forEach((el) => el.remove());

    const editorBlocks = invalidBlocks.editors[editorId]?.blocks || [];
    const allEditorIds = Object.keys(invalidBlocks.editors);
    const currentEditorIndex = allEditorIds.indexOf(
      invalidBlocks.currentEditorId!
    );
    const isFirstEditor = currentEditorIndex === 0;
    const isLastEditor = currentEditorIndex === allEditorIds.length - 1;

    editorBlocks.forEach((invalidBlock, idx) => {
      const blockElement = editor.querySelector(
        `.ce-block[data-id="${invalidBlock.blockId}"]`
      ) as HTMLElement;
      if (blockElement) {
        blockElement.style.border = "2px solid red";

        if (
          editorId === invalidBlocks.currentEditorId &&
          idx === invalidBlocks.currentIndex
        ) {
          const errorContainer = document.createElement("div");
          errorContainer.className = "invalid-block-error";
          errorContainer.style.position = "absolute";
          errorContainer.style.top = "0";
          errorContainer.style.right = "0";
          errorContainer.style.zIndex = "1000";

          const errorMessage = document.createElement("div");
          errorMessage.textContent = truncate(invalidBlock.message, 80);
          errorMessage.style.backgroundColor = "rgba(255, 0, 0, 0.1)";
          errorMessage.style.padding = "5px";
          errorMessage.style.marginBottom = "5px";

          const buttonContainer = document.createElement("div");
          buttonContainer.style.display = "flex";
          buttonContainer.style.justifyContent = "flex-end";

          const prevButton = document.createElement("button");
          prevButton.textContent = "▲";
          prevButton.style.marginRight = "5px";
          prevButton.disabled = isFirstEditor && idx === 0;
          prevButton.onclick = () => navigateInvalidBlocks("prev");

          const nextButton = document.createElement("button");
          nextButton.textContent = "▼";
          nextButton.disabled = isLastEditor && idx === editorBlocks.length - 1;
          nextButton.onclick = () => navigateInvalidBlocks("next");

          buttonContainer.appendChild(prevButton);
          buttonContainer.appendChild(nextButton);

          errorContainer.appendChild(errorMessage);
          errorContainer.appendChild(buttonContainer);

          blockElement.style.position = "relative";
          blockElement.appendChild(errorContainer);
        }
      }
    });
  };

  const navigateInvalidBlocks = (direction: "prev" | "next") => {
    const allEditorIds = Object.keys(invalidBlocks.editors);
    const currentEditorIndex = allEditorIds.indexOf(
      invalidBlocks.currentEditorId!
    );
    let newEditorId = invalidBlocks.currentEditorId;
    let newIndex = invalidBlocks.currentIndex;

    if (direction === "next") {
      if (newIndex < invalidBlocks.editors[newEditorId!].blocks.length - 1) {
        newIndex++;
      } else if (currentEditorIndex < allEditorIds.length - 1) {
        newEditorId = allEditorIds[currentEditorIndex + 1];
        newIndex = 0;
      }
    } else {
      // 'prev'
      if (newIndex > 0) {
        newIndex--;
      } else if (currentEditorIndex > 0) {
        newEditorId = allEditorIds[currentEditorIndex - 1];
        newIndex = invalidBlocks.editors[newEditorId].blocks.length - 1;
      }
    }

    dispatch(
      actions.setCurrentInvalidBlock({
        editorId: newEditorId!,
        index: newIndex,
      })
    );

    // Scroll to the new block
    setTimeout(() => {
      const newBlockElement = document.querySelector(
        `#editor-${newEditorId} .ce-block[data-id="${
          invalidBlocks.editors[newEditorId!].blocks[newIndex].blockId
        }"]`
      );
      if (newBlockElement) {
        newBlockElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "droppableItem") {
      if (
        result.destination.index > 1 &&
        result.destination.index == result.source.index
      ) {
        dispatch(
          actions.setIsUpdatingElementSequenceModal({
            isMoveDown: true,
            elementName: "topics",
            elementId: result.draggableId,
            sequence_no: ++result.destination.index,
          })
        );
      } else {
        dispatch(
          actions.setIsUpdatingElementSequenceModal({
            isMoveDown: false,
            elementName: "topics",
            elementId: result.draggableId,
            sequence_no: ++result.destination.index,
          })
        );
      }
    } else if (result.type.includes("droppableSubItem")) {
      const elType = handleGetElement(result.draggableId);
      if (
        result.destination.index > 1 &&
        result.destination.index == result.source.index
      ) {
        dispatch(
          actions.setIsUpdatingElementSequenceModal({
            isMoveDown: true,
            elementName: elType,
            elementId: result.draggableId,
            sequence_no: ++result.destination.index,
          })
        );
      } else {
        dispatch(
          actions.setIsUpdatingElementSequenceModal({
            isMoveDown: false,
            elementName: elType,
            elementId: result.draggableId,
            sequence_no: ++result.destination.index,
          })
        );
      }
    }
  };

  const parseContents = (
    topics?: Record<string, ITopicModel>
  ): Record<string, OutputData> | undefined => {
    if (!topics) return;

    const res = Object.keys(topics)
      .sort((a, b) => topics[a].sequence_number - topics[b].sequence_number)
      .reduce((acc: Record<string, OutputData>, topic_id) => {
        const topic = topics[topic_id];
        const topicContent = { ...topic.contents };

        topicContent[topic_id] = {
          sequence_number: 0,
          modified_date: topic.modified_date,
          type: "topic",
          contents: [
            {
              id: topic_id,
              text: topic.title,
              modified_date: topic.modified_date,
            },
          ],
        };

        const parsedCont = parseContent(topicContent);
        if (parsedCont) acc[topic_id] = parsedCont;

        const sortedLessons = Object.keys(topic.lessons).sort(
          (a, b) =>
            topic.lessons[a].sequence_number - topic.lessons[b].sequence_number
        );

        sortedLessons.forEach((lesson_id) => {
          const lesson = topic.lessons[lesson_id];
          const lessonContent = { ...lesson.contents };

          lessonContent[lesson_id] = {
            sequence_number: 0,
            modified_date: lesson.modified_date,
            type: "lesson",
            contents: [
              {
                id: lesson_id,
                text: lesson.title,
                modified_date: lesson.modified_date,
              },
            ],
          };

          const parsedLess = parseContent(lessonContent);
          if (parsedLess) acc[lesson_id] = parsedLess;

          const sortedPoints = Object.keys(lesson.points).sort(
            (a, b) =>
              lesson.points[a].sequence_number -
              lesson.points[b].sequence_number
          );

          sortedPoints.forEach((point_id) => {
            const point = lesson.points[point_id];
            const pointContent = { ...point.contents };

            pointContent[point_id] = {
              sequence_number: 0,
              modified_date: point.modified_date,
              type: "point",
              contents: [
                {
                  id: point_id,
                  text: point.title,
                  modified_date: point.modified_date,
                },
              ],
            };

            const parsedPoint = parseContent(pointContent);
            if (parsedPoint) acc[point_id] = parsedPoint;

            const sortedSubPoints = Object.keys(point.sub_points).sort(
              (a, b) =>
                point.sub_points[a].sequence_number -
                point.sub_points[b].sequence_number
            );

            sortedSubPoints.forEach((sub_point_id) => {
              const subPoint = point.sub_points[sub_point_id];
              const subPointContent = { ...subPoint.contents };

              subPointContent[sub_point_id] = {
                sequence_number: 0,
                modified_date: subPoint.modified_date,
                type: "sub_point",
                contents: [
                  {
                    id: sub_point_id,
                    text: subPoint.title,
                    modified_date: subPoint.modified_date,
                  },
                ],
              };

              const parsedSubPoint = parseContent(subPointContent);
              if (parsedSubPoint) acc[sub_point_id] = parsedSubPoint;
            });
          });
        });

        return acc;
      }, {});

    return res;
  };

  function optimizeChanges(
    content?: {
      user: Array<{ id: string; type: string }>;
      original: Map<string, { type: string; sequence_no: number }>;
    },
    updateModal?: Record<string, any>
  ) {
    let changedBlocks: Array<{
      id: string;
      sequence_no: number;
      type: string;
    }> = [];

    if (updateModal) {
      let { elementId, sequence_no, elementName, isMoveDown } =
        updateModal || {};
      if (elementId && sequence_no !== undefined) {
        changedBlocks.push({
          id: elementId,
          sequence_no: isMoveDown ? --sequence_no : sequence_no,
          type: elementName,
        });
      }
    } else if (content) {
      const { user, original } = content;
      const structuralElements = ["topics", "lessons", "points", "subpoints"];

      let contentIndex = 0;
      user.forEach((element, index) => {
        if (!structuralElements.includes(element.type)) {
          const originalElement = original.get(element.id);
          if (originalElement) {
            if (originalElement.sequence_no !== contentIndex + 1) {
              changedBlocks.push({
                id: element.id,
                sequence_no: contentIndex + 1,
                type: element.type,
              });
            }
          }
          contentIndex++;
        }
      });
    }

    return changedBlocks;
  }

  const findDiff = () => {
    if (module.isEditorEmpty) {
      if (editorRef) {
        editorRef.current?.map(async (editor) => {
          if (editor?.destroy && typeof editor.destroy == "function") {
            await editor.destroy();
          }
        });
      }
      dispatch(actions.setIsEditorEmpty(false));
    }

    if (module?.isUpdatingElementSequenceModal) {
      const swappedEls = optimizeChanges(
        undefined,
        module.isUpdatingElementSequenceModal
      );
      if (swappedEls) {
        dispatch(actions.setParentElsSequenceChange(swappedEls));
        dispatch(
          actions.sortModuleItems({ ...swappedEls[swappedEls.length - 1] })
        );
      }
    }

    if (
      !module.isElementAdding &&
      changedIdx.current !== null &&
      editorData &&
      Object.values(editorData).length
    ) {
      // const regex = /<\s*i\s*>(.*?)<\s*\/\s*i\s*>/g;
      const _originalDataKey = Object.keys(originalData || {})[
        changedIdx.current
      ];
      const _editorDataKey = Object.keys(editorData || {})[changedIdx.current];
      const _originalData = originalData
        ? { ...originalData[_originalDataKey] }
        : { blocks: [] };
      const _data = { ...editorData[_editorDataKey] };
      const originalString = JSON.stringify(_originalData);
      const changedString = JSON.stringify(_data);
      if (originalString !== changedString) {
        const structuralElements = ["topics", "lessons", "points", "subpoints"];
        // Check for deleted structural elements
        _originalData.blocks?.forEach((originalBlock) => {
          if (
            structuralElements.includes(originalBlock.type) &&
            !_data.blocks?.find(
              (changedBlock) => changedBlock.id === originalBlock.id
            )
          ) {
            const invalidBlock: InvalidBlock = {
              blockId: originalBlock.id!,
              message: `${originalBlock.type.slice(0, -1)} cannot be empty`,
            };
            dispatch(
              actions.setInvalidBlocks({
                editorId: _editorDataKey,
                invalidBlocks: [invalidBlock],
              })
            );
          }
        });
        const result: {
          changed: Array<Record<string, any>>;
          unchanged: Array<Record<string, any>>;
          deleted: Array<Record<string, any>>;
          created: Array<Record<string, any>>;
        } = {
          changed: [],
          unchanged: [],
          deleted: [],
          created: [],
        };
        const originalMap = new Map(
          _originalData.blocks?.map((block) => [
            block.id,
            {
              id: block.id,
              type: block.type,
              data: block.data,
            },
          ])
        );
        const originalSequenceMap = new Map(
          _originalData.blocks?.map((block: any) => [
            block.id,
            {
              sequence_no: block.sequence_no,
              type: block.type,
            },
          ])
        );
        const user = { ..._data }.blocks.map((block) => ({
          id: block.id,
          type: block.type,
        }));
        const swappedEls = optimizeChanges(
          {
            original: originalSequenceMap,
            user: user as Array<{ id: string; type: string }>,
          },
          undefined
        );
        if (swappedEls.length) {
          result.changed.push(...swappedEls);
        }
        if (_data.blocks.length == 1) {
          if (
            !["topics", "lessons", "points", "subpoints"].includes(
              _data.blocks[0].type
            )
          ) {
            dispatch(actions.setIsHierarchyError(true));
            return;
          }
        }
        _data.blocks?.forEach((changedBlock: Record<string, any>, idx) => {
          // Validate structural elements
          if (structuralElements.includes(changedBlock.type)) {
            const isValid =
              changedBlock.data &&
              changedBlock.data.text &&
              changedBlock.data.text !== "";
            if (!isValid) {
              const invalidBlock: InvalidBlock = {
                blockId: changedBlock.id,
                message: `${changedBlock.type.slice(0, -1)} cannot be empty`,
              };
              dispatch(
                actions.setInvalidBlocks({
                  editorId: _editorDataKey,
                  invalidBlocks: [invalidBlock],
                })
              );
            } else {
              // If it's valid now, remove from invalidBlocks if it was there
              if (invalidBlocks.editors[_editorDataKey]) {
                const updatedInvalidBlocks = invalidBlocks.editors[
                  _editorDataKey
                ].blocks.filter((block) => block.blockId !== changedBlock.id);
                if (updatedInvalidBlocks.length === 0) {
                  dispatch(actions.clearInvalidBlocksById(_editorDataKey));
                } else {
                  dispatch(
                    actions.setInvalidBlocks({
                      editorId: _editorDataKey,
                      invalidBlocks: updatedInvalidBlocks,
                    })
                  );
                }
              }
            }
          }
          if (changedBlock && invalidBlocks.editors[_editorDataKey]) {
            const existingInvalidBlock = invalidBlocks.editors[
              _editorDataKey
            ].blocks.find((block) => block.blockId === changedBlock.id);
            if (existingInvalidBlock) {
              validateObject([changedBlock as any]).then((res) => {
                if (res.success) {
                  // Remove this block from invalidBlocks if it's now valid
                  const updatedInvalidBlocks = invalidBlocks.editors[
                    _editorDataKey
                  ].blocks.filter((block) => block.blockId !== changedBlock.id);
                  if (updatedInvalidBlocks.length === 0) {
                    dispatch(actions.clearInvalidBlocksById(_editorDataKey));
                  } else {
                    dispatch(
                      actions.setInvalidBlocks({
                        editorId: _editorDataKey,
                        invalidBlocks: updatedInvalidBlocks,
                      })
                    );
                  }
                  // Remove the red border and error message
                  const blockElement = document.querySelector(
                    `#editor-${_editorDataKey} .ce-block[data-id="${changedBlock.id}"]`
                  ) as HTMLElement;
                  if (blockElement) {
                    blockElement.style.border = "none";
                    const errorElement = blockElement.querySelector(
                      ".invalid-block-error"
                    );
                    if (errorElement) {
                      errorElement.remove();
                    }
                  }
                }
              });
            }
          }

          const originalBlock = originalMap.get(changedBlock.id);
          //created
          if (!originalBlock) {
            const parentElements = findElementParents(
              _editorDataKey,
              module.result || {}
            );
            let parentId: string;
            let elementName: string;
            let insertIndex: number;

            switch (changedBlock.type) {
              case "topics":
                parentId = module.result?.id!;
                elementName = "topic";
                insertIndex = Object.keys(module.result?.topics || {}).length;
                break;
              case "lessons":
                parentId = parentElements!.find((id) => id.startsWith("T"));
                elementName = "lesson";

                insertIndex = _editorDataKey.startsWith("T")
                  ? 0
                  : module.result?.topics?.[parentId]?.lessons[_editorDataKey]
                      .sequence_number || 0;
                break;
              case "points":
                parentId =
                  parentElements!.find((id) => id.startsWith("L")) ||
                  _editorDataKey;
                elementName = "point";
                insertIndex = _editorDataKey.startsWith("L")
                  ? 0
                  : module.result?.topics?.[
                      parentElements!.find((id) => id.startsWith("T"))
                    ]?.lessons?.[parentId]?.points[_editorDataKey]
                      .sequence_number || 0;
                break;
              case "subpoints":
                parentId =
                  parentElements!.find((id) => id.startsWith("P")) ||
                  _editorDataKey;
                elementName = "sub_point";
                insertIndex = _editorDataKey.startsWith("P")
                  ? 0
                  : module.result?.topics?.[
                      parentElements!.find((id) => id.startsWith("T"))
                    ]?.lessons?.[
                      parentElements!.find((id) => id.startsWith("L"))
                    ]?.points?.[parentId]?.sub_points[_editorDataKey]
                      .sequence_number || 0;
                break;
              default:
                parentId = module.result?.id!;
                elementName = "topic";
                insertIndex = 0;
            }

            const sequence_number = insertIndex + 1;

            if (
              ["topics", "lessons", "points", "subpoints"].includes(
                changedBlock.type
              )
            ) {
              dispatch(
                actions.createElement({
                  element_id: parentId,
                  sequence_no: sequence_number,
                  element_name: elementName,
                  title: changedBlock.data.text,
                })
              );
              return;
            } else {
              result.created.push({
                ...changedBlock,
                sequence_no: idx,
              });
            }
          }
          // updated
          else if (
            JSON.stringify(originalBlock.data) !==
            JSON.stringify(changedBlock.data)
          ) {
            if (changedBlock.type == "table") {
              for (let i = 0; i < changedBlock.data.content.length; i++) {
                for (let j = 0; j < changedBlock.data.content[i].length; j++) {
                  if (
                    changedBlock.data.content[i][j] !==
                    originalMap.get(changedBlock.id)?.data.content[i][j]
                  ) {
                    result.changed.push({
                      ...changedBlock,
                      cell: { row: i + 1, col: j + 1 },
                      id: changedBlock.id,
                    });
                  }
                }
              }
            } else if (changedBlock.type == "list") {
              result.deleted.push(originalBlock);
              result.created.push({
                ...changedBlock,
                sequence_no: idx,
              });
            } else if (changedBlock.type == "paragraph") {
              // if (changedBlock.data.text.match(regex)) {
              //   result.changed.push({
              //     ...changedBlock,
              //     data: {
              //       text: changedBlock["data"]["text"].replace(regex, "$1"),
              //     },
              //     italic: true,
              //     id: changedBlock.id,
              //   });
              // } else {
              result.changed.push({
                ...changedBlock,
                italic: false,
                id: changedBlock.id,
              });
              // }
            } else if (changedBlock.type == "topics") {
              result.changed.push({
                element_id: _editorDataKey,
                type: "topic",
                title: changedBlock.data.text,
              });
            } else if (changedBlock.type == "lessons") {
              result.changed.push({
                element_id: _editorDataKey,
                type: "lesson",
                title: changedBlock.data.text,
              });
            } else if (changedBlock.type == "points") {
              result.changed.push({
                element_id: _editorDataKey,
                type: "point",
                title: changedBlock.data.text,
              });
            } else if (changedBlock.type == "subpoints") {
              result.changed.push({
                element_id: _editorDataKey,
                type: "sub_point",
                title: changedBlock.data.text,
              });
            } else {
              result.unchanged.push(changedBlock);
            }
          }
        });
        // deleted
        _originalData.blocks?.forEach((originalBlock) => {
          if (
            !_data.blocks?.find(
              (changedBlock) => changedBlock.id === originalBlock.id
            )
          ) {
            result.deleted.push(originalBlock);
          }
        });
        if (invalidBlocks.editors[_editorDataKey]) {
          const currentBlockIds = new Set(
            _data.blocks.map((block) => block.id)
          );
          const updatedInvalidBlocks = invalidBlocks.editors[
            _editorDataKey
          ].blocks.filter((invalidBlock) =>
            currentBlockIds.has(invalidBlock.blockId)
          );

          if (updatedInvalidBlocks.length === 0) {
            dispatch(actions.clearInvalidBlocksById(_editorDataKey));
          } else if (
            updatedInvalidBlocks.length !==
            invalidBlocks.editors[_editorDataKey].blocks.length
          ) {
            dispatch(
              actions.setInvalidBlocks({
                editorId: _editorDataKey,
                invalidBlocks: updatedInvalidBlocks,
              })
            );

            if (
              invalidBlocks.currentEditorId === _editorDataKey &&
              !updatedInvalidBlocks.some(
                (block) =>
                  block.blockId ===
                  invalidBlocks.editors[_editorDataKey].blocks[
                    invalidBlocks.currentIndex
                  ].blockId
              )
            ) {
              const nextInvalidBlock = findNextInvalidBlock(
                {
                  ...invalidBlocks,
                  editors: {
                    ...invalidBlocks.editors,
                    [_editorDataKey]: { blocks: updatedInvalidBlocks },
                  },
                },
                _editorDataKey
              );
              if (nextInvalidBlock) {
                dispatch(actions.setCurrentInvalidBlock(nextInvalidBlock));
              }
            }
          }
        }
        console.log(result);
        if (
          (result.changed.length ||
            result.created.length ||
            result.deleted.length) &&
          changedIdx.current != null
        ) {
          if (!isUndoRedoInProgressRef.current) {
            const existingIndex = editorStackRef.current.indexOf(
              changedIdx.current
            );
            if (existingIndex !== -1) {
              editorStackRef.current.splice(existingIndex, 1);
            }
            editorStackRef.current.unshift(changedIdx.current);

            // Reset the pointer to the first element
            editorStackPointerRef.current = 0;
          }
        }
        dispatch(actions.setContent({ [_editorDataKey]: result }));
      } else {
        console.log("Objects are identical.");
      }
    }
  };

  const handleLock = (lock: boolean) => {
    dispatch(actions.lockModule(lock));
  };

  const columns = [
    { field: "glossary_id", headerName: "ID", width: 70 },
    { field: "en_phrase", headerName: "English Phrase", width: 200 },
    {
      field: "target_phrase",
      headerName: `Target Phrase`,
      width: 200,
    },
  ];

  const handleUseGlossaryTranslate = () => {
    dispatch(
      actions.translateLanguage({
        lang: module.isGlossaryModalOpen,
        useGlossary: true,
      })
    );
    dispatch(actions.setIsGlossaryModalOpen(""));
    dispatch(actions.setClearGlossary());
  };

  const handleNoGlossaryTranslate = () => {
    dispatch(
      actions.translateLanguage({
        lang: module.isGlossaryModalOpen,
        useGlossary: false,
      })
    );
    dispatch(actions.setIsGlossaryModalOpen(""));
    dispatch(actions.setClearGlossary());
  };

  const handleCloseGlossary = () => {
    dispatch(actions.setIsGlossaryModalOpen(""));
    dispatch(actions.setClearGlossary());
    dispatch(actions.setLanguageValue(ILanguage.en));
  };

  const handleCheckHasChange = (lang: string) => {
    const res = hasChanges(module.content);
    if (res) {
      dispatch(actions.setIsHasChangesModal(lang));
    } else {
      handleLanguageChange(lang);
    }
    setAnchorElLanguage(null);
  };

  const handleConfirmUpdateSequence = () => {
    if (module?.isUpdatingElementSequenceModal) {
      findDiff();
    }
  };

  const handleLanguageChange = (lang: string) => {
    dispatch(glossaryActions.getGlossaries(lang));
    dispatch(actions.setIsGlossaryModalOpen(lang));
    dispatch(actions.setIsHasChangesModal(""));
    dispatch(actions.clearContent());
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // overflowX: "auto",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    // anchor="left"
    width: width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: isDrawerOpen ? "flex" : "none",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const RightDrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "start",
  }));

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleContentSubmit = () => {
    findDiff();
    dispatch(actions.manageContent());
  };

  const handleDownloadFile = (
    itemType: "isImage" | "isPdf" | "isPpt",
    itemId?: string
  ) => {
    if (itemId) dispatch(actions.downloadFile({ itemId, itemType }));
  };

  const handleDownloadPPt = (id) => {
    dispatch(actions.downloadPPT(id));
  };

  const handleOpenImageViewerModal = (
    type: "isPpt" | "isPdf" | "isImage",
    isTopic?: string
  ) => {
    if (isTopic) {
      setIsTopic(isTopic);
    }
    if (type == "isImage") {
      setItemType(type);
      setImageViewerOpen(true);
    }
    if (type == "isPdf") {
      window.open(module.result?.pdf_url, "_blank");
    } else if (type == "isPpt") {
      window.open(module.result?.ppt_url, "_blank");
    }
  };

  const handleCloseImageViewerModal = () => {
    setIsTopic("");
    setImageViewerOpen(false);
  };

  const Sidebar = () => {
    const renderSubPoints = (
      pointId: string,
      subPoints: Record<string, any>,
      indentLevel
    ) => {
      return (
        <>
          {role == "viewer" ? (
            <>
              {Object.keys(subPoints)
                ?.sort(
                  (a, b) =>
                    subPoints[a].sequence_number - subPoints[b].sequence_number
                )
                .map((subPointKey, index) => {
                  const subPoint = subPoints[subPointKey];
                  return (
                    <ListItem
                      sx={{
                        display: isDrawerOpen ? "flex" : "none",
                        "&.MuiListItem-root": {
                          padding: "0px",
                          opacity: isDrawerOpen ? 1 : 0,
                          paddingLeft: `${indentLevel * 32}px`,
                          color: visibleSection
                            ? visibleSection == subPointKey
                              ? "#0d5eba"
                              : "#00000e"
                            : "00000e",
                          cursor: "pointer",
                        },
                      }}
                      disableGutters
                      onClick={() => handleItemClick(subPointKey)}
                    >
                      <ListItemText
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontSize: "0.82rem",
                          },
                          opacity: isDrawerOpen ? 1 : 0,
                        }}
                        primary={truncate(subPoint.title, truncateValue)}
                      />
                    </ListItem>
                  );
                })}
            </>
          ) : (
            <Droppable
              droppableId={pointId}
              type={`droppableSubItemSubPoint-${pointId}`}
            >
              {(provided, snapshot) => (
                <Box ref={provided.innerRef}>
                  {Object.keys(subPoints)
                    .sort(
                      (a, b) =>
                        subPoints[a].sequence_number -
                        subPoints[b].sequence_number
                    )
                    .map((subPointKey, index) => {
                      const subPoint = subPoints[subPointKey];
                      return (
                        <Draggable
                          key={subPointKey}
                          draggableId={subPointKey}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{
                                display: isDrawerOpen ? "flex" : "none",
                                "&.MuiListItem-root": {
                                  padding: "0px",
                                  opacity: isDrawerOpen ? 1 : 0,
                                  paddingLeft: `${indentLevel * 32}px`,
                                  color: visibleSection
                                    ? visibleSection == subPointKey
                                      ? "#0d5eba"
                                      : "#00000e"
                                    : "#00000e",
                                  position: "relative",
                                  "&:hover .delete-icon": {
                                    display: "block",
                                  },
                                },
                              }}
                              disableGutters
                              onClick={() => handleItemClick(subPointKey)}
                            >
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "0.82rem",
                                  },
                                  opacity: isDrawerOpen ? 1 : 0,
                                }}
                                primary={truncate(
                                  subPoint.title,
                                  truncateValue
                                )}
                              />
                              <IconButton
                                className="delete-icon"
                                sx={{
                                  display: "none",
                                  position: "absolute",
                                  right: "8px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteElement({
                                    elementName: "Sub-Point",
                                    elementId: subPointKey,
                                  });
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </ListItem>
                          )}
                        </Draggable>
                      );
                    })}
                </Box>
              )}
            </Droppable>
          )}
        </>
      );
    };

    const renderPoints = (lessonId, points, indentLevel) => {
      return (
        <>
          {role == "viewer" ? (
            <>
              {Object.keys(points)
                ?.sort(
                  (a, b) =>
                    points[a].sequence_number - points[b].sequence_number
                )
                .map((pointKey, index) => {
                  const point = points[pointKey];
                  return (
                    <>
                      <ListItem
                        sx={{
                          display: isDrawerOpen ? "flex" : "none",
                          "&.MuiListItem-root": {
                            opacity: isDrawerOpen ? 1 : 0,

                            padding: "0px",
                            paddingLeft: `${indentLevel * 32}px`,
                            color: visibleSection
                              ? visibleSection == pointKey
                                ? "#0d5eba"
                                : "#00000e"
                              : "00000e",
                            cursor: "pointer",
                          },
                        }}
                        disableGutters
                        onClick={() => handleItemClick(pointKey)}
                      >
                        <ListItemText
                          primary={truncate(point.title, truncateValue)}
                          sx={{
                            "& .MuiListItemText-primary": {
                              fontSize: "0.82rem",
                            },
                            opacity: isDrawerOpen ? 1 : 0,
                          }}
                        />
                      </ListItem>
                      {point.sub_points &&
                        renderSubPoints(
                          pointKey,
                          point.sub_points,
                          indentLevel + 1
                        )}
                    </>
                  );
                })}
            </>
          ) : (
            <Droppable
              droppableId={lessonId}
              type={`droppableSubItemPoint-${lessonId}`}
            >
              {(provided) => (
                <Box ref={provided.innerRef}>
                  {Object.keys(points)
                    .sort(
                      (a, b) =>
                        points[a].sequence_number - points[b].sequence_number
                    )
                    .map((pointKey, index) => {
                      const point = points[pointKey];
                      return (
                        <Draggable
                          key={pointKey}
                          draggableId={pointKey}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <ListItem
                                sx={{
                                  display: isDrawerOpen ? "flex" : "none",
                                  "&.MuiListItem-root": {
                                    padding: "0px",
                                    opacity: isDrawerOpen ? 1 : 0,
                                    paddingLeft: `${indentLevel * 32}px`,
                                    color: visibleSection
                                      ? visibleSection == pointKey
                                        ? "#0d5eba"
                                        : "#00000e"
                                      : "#00000e",
                                    "&:hover .delete-icon": {
                                      display: "flex",
                                    },
                                  },
                                }}
                                disableGutters
                                onClick={() => handleItemClick(pointKey)}
                              >
                                <ListItemText
                                  primary={truncate(point.title, truncateValue)}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: "0.82rem",
                                    },
                                    opacity: isDrawerOpen ? 1 : 0,
                                  }}
                                />
                                <IconButton
                                  className="delete-icon"
                                  sx={{
                                    display: "none",
                                    marginLeft: "auto",
                                  }}
                                  onClick={() =>
                                    handleDeleteElement({
                                      elementName: "Point",
                                      elementId: pointKey,
                                    })
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </ListItem>
                              {point.sub_points &&
                                renderSubPoints(
                                  pointKey,
                                  point.sub_points,
                                  indentLevel + 1
                                )}
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                </Box>
              )}
            </Droppable>
          )}
        </>
      );
    };

    const renderLessons = (topicId, lessons, indentLevel) => {
      return (
        <>
          {role == "viewer" ? (
            <>
              {Object.keys(lessons)
                ?.sort(
                  (a, b) =>
                    lessons[a].sequence_number - lessons[b].sequence_number
                )
                .map((lessonKey, index) => {
                  const lesson = lessons[lessonKey];
                  return (
                    <>
                      <ListItem
                        sx={{
                          display: isDrawerOpen ? "flex" : "none",
                          "&.MuiListItem-root": {
                            opacity: isDrawerOpen ? 1 : 0,

                            padding: "0px",
                            paddingLeft: `${indentLevel * 32}px`,
                            color: visibleSection
                              ? visibleSection == lessonKey
                                ? "#0d5eba"
                                : "#00000e"
                              : "00000e",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleItemClick(lessonKey)}
                        disableGutters
                      >
                        <ListItemText
                          sx={{
                            "& .MuiListItemText-primary": {
                              fontSize: "0.82rem",
                            },
                          }}
                          primary={truncate(lesson.title, truncateValue)}
                        />
                      </ListItem>
                      {lesson.points &&
                        renderPoints(lessonKey, lesson.points, indentLevel + 1)}
                    </>
                  );
                })}
            </>
          ) : (
            <Droppable
              droppableId={topicId}
              type={`droppableSubItemLesson-${topicId}`}
            >
              {(provided) => (
                <Box ref={provided.innerRef}>
                  {Object.keys(lessons)
                    ?.sort(
                      (a, b) =>
                        lessons[a].sequence_number - lessons[b].sequence_number
                    )
                    .map((lessonKey, index) => {
                      const lesson = lessons[lessonKey];
                      return (
                        <Draggable
                          key={lessonKey}
                          draggableId={lessonKey}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <ListItem
                                sx={{
                                  display: isDrawerOpen ? "flex" : "none",
                                  "&.MuiListItem-root": {
                                    padding: "0px",
                                    opacity: isDrawerOpen ? 1 : 0,
                                    paddingLeft: `${indentLevel * 32}px`,
                                    color: visibleSection
                                      ? visibleSection == lessonKey
                                        ? "#0d5eba"
                                        : "#00000e"
                                      : "#00000e",
                                    "&:hover .delete-icon": {
                                      display: "flex",
                                    },
                                  },
                                }}
                                onClick={() => handleItemClick(lessonKey)}
                                disableGutters
                              >
                                <ListItemText
                                  primary={truncate(
                                    lesson.title,
                                    truncateValue
                                  )}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: "0.82rem",
                                    },
                                    opacity: isDrawerOpen ? 1 : 0,
                                  }}
                                />
                                <IconButton
                                  className="delete-icon"
                                  sx={{
                                    display: "none",
                                    marginLeft: "auto",
                                  }}
                                  onClick={() =>
                                    handleDeleteElement({
                                      elementName: "Lesson",
                                      elementId: lessonKey,
                                    })
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </ListItem>
                              {lesson.points &&
                                renderPoints(
                                  lessonKey,
                                  lesson.points,
                                  indentLevel + 1
                                )}
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                </Box>
              )}
            </Droppable>
          )}
        </>
      );
    };

    return (
      <>
        <Drawer
          sx={{
            "& .MuiPaper-root": {
              marginTop: "68px",
              background: "transparent",
              height: "calc(100% - 68px)", // Set a fixed height
              overflowY: "auto",
              overflowX: "hidden",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          open={isDrawerOpen}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isDrawerOpen && (
              <Box
                sx={{
                  display: "flex",
                  height: `calc(100% - 68px)`,
                  alignItems: "center",
                }}
              >
                <Box
                  onClick={() => setIsDrawerOpen(true)}
                  sx={{
                    width: "35px",
                    height: "150px",
                    display: "flex",
                    bottom: "150px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f7ff",
                    border: "1px solid #e5eaf280",
                    borderRadius: "0px 12px 12px 0px",
                    "&:hover": {
                      borderColor: "#66b2ff",
                    },
                  }}
                >
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            )}

            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider
              sx={{
                display: isDrawerOpen ? "block" : "none",
              }}
            />
            {role == "viewer" ? (
              <List
                sx={{
                  display: isDrawerOpen ? "block" : "none",
                  flexGrow: 1,
                  overflowY: "auto",
                  paddingBottom: "100px",
                  overflowX: "hidden",
                }}
              >
                {module?.result?.topics &&
                  Object.keys(module.result.topics)
                    ?.sort(
                      (a, b) =>
                        (module?.result?.topics?.[a]?.sequence_number || 0) -
                        (module?.result?.topics?.[b]?.sequence_number || 0)
                    )
                    .map((topicId, index) => {
                      const topic = module?.result?.topics?.[topicId];
                      return (
                        <>
                          <ListItem
                            sx={{
                              "&.MuiListItem-root": {
                                padding: "0px",
                                opacity: isDrawerOpen ? 1 : 0,
                                paddingLeft: "16px",
                                color: visibleSection
                                  ? visibleSection == topicId
                                    ? "#0d5eba"
                                    : "#00000e"
                                  : "00000e",
                                cursor: "pointer",
                              },
                            }}
                            disableGutters
                            onClick={() => handleItemClick(topicId)}
                          >
                            <ListItemText
                              sx={{
                                "& .MuiListItemText-primary": {
                                  fontSize: "0.82rem",
                                },
                              }}
                              primary={topic?.title}
                            />
                          </ListItem>
                          {topic?.lessons &&
                            renderLessons(topicId, topic.lessons, 1)}
                        </>
                      );
                    })}
              </List>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="droppableItem">
                  {(provided, snapshot) => (
                    <List
                      ref={provided.innerRef}
                      sx={{
                        display: isDrawerOpen ? "block" : "none",
                      }}
                    >
                      {module?.result?.topics &&
                        Object.keys(module.result.topics)
                          ?.sort(
                            (a, b) =>
                              (module?.result?.topics?.[a]?.sequence_number ||
                                0) -
                              (module?.result?.topics?.[b]?.sequence_number ||
                                0)
                          )
                          .map((topicId, index) => {
                            const topic = module?.result?.topics?.[topicId];
                            return (
                              <Draggable
                                key={topicId}
                                draggableId={topicId}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <ListItem
                                      sx={{
                                        "&.MuiListItem-root": {
                                          padding: "0px",
                                          opacity: isDrawerOpen ? 1 : 0,
                                          paddingLeft: "16px",
                                          color: visibleSection
                                            ? visibleSection == topicId
                                              ? "#0d5eba"
                                              : "#00000e"
                                            : "#00000e",
                                          "&:hover .delete-icon": {
                                            display: "flex",
                                          },
                                        },
                                      }}
                                      disableGutters
                                      onClick={() => handleItemClick(topicId)}
                                    >
                                      <ListItemText
                                        sx={{
                                          "& .MuiListItemText-primary": {
                                            fontSize: "0.82rem",
                                          },
                                        }}
                                        primary={truncate(
                                          topic?.title,
                                          truncateValue
                                        )}
                                      />
                                      <IconButton
                                        className="delete-icon"
                                        sx={{
                                          display: "none",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() =>
                                          handleDeleteElement({
                                            elementName: "Topic",
                                            elementId: topicId,
                                          })
                                        }
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </ListItem>
                                    {topic?.lessons &&
                                      renderLessons(topicId, topic.lessons, 1)}
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Box>
        </Drawer>
        {isDrawerOpen && (
          <Box
            sx={{
              position: "absolute",
              width: "4px",
              top: "68px",
              left: width,
              bottom: 0,
              backgroundColor: "transparent",
              cursor: "ew-resize",
              zIndex: "5000",
              opacity: "0",
              "&:hover": {
                backgroundColor: "#f0f7ff",
                opacity: 1,
              },
            }}
            onMouseDown={handleMouseDown}
          />
        )}
      </>
    );
  };

  return (
    <>
      <UnlockWarningModal
        open={openWarningModal}
        handleClose={() => setOpenWarningModal(false)}
        handleUnlock={() => {
          handleLock(false);
          setOpenWarningModal(false);
        }}
        isLoading={false}
      />

      <WarningModal
        type={"error"}
        title={
          !!module.isHasChangeModal || !!module.isUpdatingElementSequenceModal
            ? "Wait!"
            : "Something went wrong!"
        }
        open={
          module.isContentFailedModalOpen ||
          module.isValidationModalOpen ||
          module.lockErrorWarningModal ||
          module.imageGenerateWarningModal ||
          module.hierarchyError ||
          !!module.isHasChangeModal ||
          !!module.isUpdatingElementSequenceModal
        }
        handleClose={() => handleWarningModalClose()}
        hideActions
        hideChildren
      >
        {module.lockErrorWarningModal ? (
          <Grid
            container
            sx={{
              width: "100%",
              padding: "12px 16px",
            }}
          >
            {!!(
              module.lockError?.sections_without_contents["topics"]?.length ||
              module.lockError?.sections_without_contents["lessons"]?.length ||
              module.lockError?.sections_without_contents["points"]?.length ||
              module.lockError?.sections_without_contents["sub_points"]?.length
            ) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Generate content for this element/s:
                  </Typography>
                </Grid>
                <List>
                  {Object.keys(
                    module.lockError?.sections_without_contents || {}
                  )?.map((key) => (
                    <>
                      {module.lockError?.sections_without_contents[key]?.map(
                        (content) => (
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                "&.MuiListItemIcon-root": {
                                  minWidth: "0px",
                                  paddingRight: "8px",
                                },
                              }}
                            >
                              <Typography variant="body2" color="error">
                                *
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                color: "#0d5eba",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              primary={content.element_name}
                              onClick={() => {
                                handleItemClick(content.element_id);
                                handleWarningModalClose();
                              }}
                            />
                          </ListItem>
                        )
                      )}
                    </>
                  ))}
                </List>
              </>
            )}
            {!!(
              Object.values(
                module?.lockError?.sections_without_images.topics || {}
              ).length ||
              Object.values(
                module?.lockError?.sections_without_images.modules || {}
              ).length
            ) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Please generate images for:
                  </Typography>
                </Grid>
                <List>
                  {Object.keys(
                    module.lockError?.sections_without_images || {}
                  )?.map((key) => (
                    <>
                      {module.lockError?.sections_without_images[key]?.map(
                        (image) => (
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                "&.MuiListItemIcon-root": {
                                  minWidth: "0px",
                                  paddingRight: "8px",
                                },
                              }}
                            >
                              <Typography variant="body2" color="error">
                                *
                              </Typography>
                            </ListItemIcon>
                            <ListItemText primary={image.element_name} />
                          </ListItem>
                        )
                      )}
                    </>
                  ))}
                </List>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    onClick={() => {
                      dispatch(actions.bulkDownloadFile());
                    }}
                    variant="contained"
                  >
                    Generate All
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <>
            {module.isValidationModalOpen ? (
              <Grid
                container
                sx={{
                  width: "100%",
                  padding: "12px 16px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="h5">
                    You seem to have wrong entries in doc. Please check if:
                  </Typography>
                </Grid>
                <List>
                  <ListItem>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "0px",
                          paddingRight: "8px",
                        },
                      }}
                    >
                      <Typography variant="body2" color="error">
                        *
                      </Typography>
                    </ListItemIcon>
                    <ListItemText
                      primary={"Paragraphs are at least 4 characters"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "0px",
                          paddingRight: "8px",
                        },
                      }}
                    >
                      <Typography variant="body2" color="error">
                        *
                      </Typography>
                    </ListItemIcon>
                    <ListItemText primary={"Paragraphs are not only numbers"} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "0px",
                          paddingRight: "8px",
                        },
                      }}
                    >
                      <Typography variant="body2" color="error">
                        *
                      </Typography>
                    </ListItemIcon>
                    <ListItemText
                      primary={"List Items are at least 4 characters"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "0px",
                          paddingRight: "8px",
                        },
                      }}
                    >
                      <Typography variant="body2" color="error">
                        *
                      </Typography>
                    </ListItemIcon>
                    <ListItemText primary={"List Items are not only numbers"} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{
                        "&.MuiListItemIcon-root": {
                          minWidth: "0px",
                          paddingRight: "8px",
                        },
                      }}
                    >
                      <Typography variant="body2" color="error">
                        *
                      </Typography>
                    </ListItemIcon>
                    <ListItemText primary={"OR if any content is empty"} />
                  </ListItem>
                </List>
                <Grid
                  item
                  xs={12}
                  sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleWarningModalClose();
                      const firstEditorId = Object.keys(
                        invalidBlocks.editors
                      )[0];
                      if (firstEditorId) {
                        dispatch(
                          actions.setCurrentInvalidBlock({
                            editorId: firstEditorId,
                            index: 0,
                          })
                        );
                        const firstInvalidBlock =
                          invalidBlocks.editors[firstEditorId].blocks[0];
                        const blockElement = document.querySelector(
                          `#editor-${firstEditorId} .ce-block[data-id="${firstInvalidBlock.blockId}"]`
                        );
                        if (blockElement) {
                          blockElement.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }
                        // Re-highlight blocks after navigation
                        Object.keys(invalidBlocks.editors).forEach(
                          (editorId) => {
                            highlightInvalidBlocks(editorId, invalidBlocks);
                          }
                        );
                      }
                    }}
                  >
                    Go to First Invalid Block
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                {module.isContentFailedModalOpen ? (
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      padding: "12px 16px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Something went wrong with the following and are not
                        saved:
                      </Typography>
                    </Grid>
                    <List>
                      {module?.contentError?.map((item) => (
                        <ListItem>
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "0px",
                                paddingRight: "8px",
                              },
                            }}
                          >
                            <Typography variant="body2" color="error">
                              *
                            </Typography>
                          </ListItemIcon>
                          <ListItemText primary={item} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ) : (
                  <>
                    {module.hierarchyError ? (
                      <Grid
                        container
                        sx={{
                          width: "100%",
                          padding: "12px 16px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Please adhere to the heirarchy:
                          </Typography>
                        </Grid>
                        <Typography variant="body2" color="error">
                          Topics - Lessons - Points - SubPoints
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        {module.isHasChangeModal ? (
                          <Grid
                            container
                            sx={{
                              width: "100%",
                              padding: "12px 16px",
                            }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                You have unsaved changes. Are you sure you want
                                to discard?
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ pt: 5 }}
                              container
                              rowSpacing={2}
                              justifyContent="end"
                            >
                              <Button
                                onClick={() => {
                                  handleLanguageChange(module.isHasChangeModal);
                                }}
                                variant="outlined"
                              >
                                Yes
                              </Button>
                              <Button
                                onClick={() => {
                                  dispatch(actions.setIsHasChangesModal(""));
                                }}
                                variant="ghost"
                              >
                                No
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            {module.isUpdatingElementSequenceModal && (
                              <Grid
                                container
                                sx={{
                                  width: "100%",
                                  padding: "12px 16px",
                                }}
                              >
                                <Grid item xs={12}>
                                  <Typography variant="h5">
                                    Are you sure you want to update sequence of
                                    elements?
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ pt: 5 }}
                                  container
                                  rowSpacing={2}
                                  justifyContent="end"
                                >
                                  <Button
                                    onClick={() => {
                                      handleConfirmUpdateSequence();
                                    }}
                                    variant="outlined"
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      dispatch(
                                        actions.setIsUpdatingElementSequenceModal(
                                          undefined
                                        )
                                      );
                                    }}
                                    variant="ghost"
                                  >
                                    No
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <>
          {module?.imageGenerateWarningModal &&
          !module?.imageGenerationError?.isAll ? (
            <Grid
              container
              sx={{
                width: "100%",
                padding: "12px 16px",
              }}
            >
              {(Object.values(
                module?.imageGenerationError?.sections_without_images.topics ||
                  {}
              ).length ||
                Object.values(
                  module?.imageGenerationError?.sections_without_images
                    .module || {}
                ).length) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Failed to generate images for:
                    </Typography>
                  </Grid>
                  <List>
                    {Object.keys(
                      module.imageGenerationError?.sections_without_images || {}
                    )?.map((key) => (
                      <>
                        {module.imageGenerationError?.sections_without_images[
                          key
                        ]?.map((image) => (
                          <ListItem>
                            <ListItemIcon
                              sx={{
                                "&.MuiListItemIcon-root": {
                                  minWidth: "0px",
                                  paddingRight: "8px",
                                },
                              }}
                            >
                              <Typography variant="body2" color="error">
                                *
                              </Typography>
                            </ListItemIcon>
                            <ListItemText primary={image.element_name} />
                          </ListItem>
                        ))}
                      </>
                    ))}
                  </List>
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      onClick={() => {
                        dispatch(actions.bulkDownloadFile());
                      }}
                      variant="contained"
                    >
                      Try Again
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <>
              {module?.imageGenerateWarningModal &&
                module?.imageGenerationError?.isAll && (
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      padding: "12px 16px",
                    }}
                  >
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <Typography>
                        Failed to generate images for all entities. Try Again.
                      </Typography>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center">
                      <Button
                        onClick={() => {
                          dispatch(actions.bulkDownloadFile());
                        }}
                        variant="contained"
                      >
                        Try Again
                      </Button>
                    </Grid>
                  </Grid>
                )}
            </>
          )}
        </>
      </WarningModal>

      <ImageViewerModal
        itemType={itemType}
        isOpen={imageViewerOpen}
        module={module.result}
        handleClose={handleCloseImageViewerModal}
        isTopic={isTopic}
      />

      <GlossaryModal
        columns={columns}
        targetModuleGlossary={module.targetModuleGlossary}
        handleClose={handleCloseGlossary}
        handleSelectionChange={handleSelectionChange}
        isLoading={glossaries.isGlossaryLoading}
        handleUseGlossaryTranslate={handleUseGlossaryTranslate}
        handleNoGlossaryTranslate={handleNoGlossaryTranslate}
        open={module.isGlossaryModalOpen}
        selectedGlossaryIds={module.selectedGlossaryIds}
      />

      <CustomModal open={uploadImageModalOpen} handleClose={handleClose}>
        <FileUploader
          onClose={handleClose}
          onFileSelect={handleFileSelect}
          onUpload={handleUpload}
          file={file}
          uploadState={uploadState}
        />
      </CustomModal>

      <Box
        sx={{
          display: `${
            module.isContentLoading ||
            module.isDownloadingItem ||
            isGlobalDownloading == "processing" ||
            module.isModuleLoading
              ? "flex"
              : "none"
          }`,
          zIndex: "4000",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          background: "rgba(0, 0, 0, 0.7) !important",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: `calc(100vh - 82px)`,
        }}
      >
        <Typography
          sx={{
            fontSize: "54px",
            fontWeight: 700,
            pt: 6,
            pb: 4,
            color: "#ffffff",
          }}
        >
          {module.isModuleLoading || module.isDownloadingItem
            ? "Please wait!"
            : "Saving, Please wait!"}
        </Typography>
        <img src={Spinner} alt="Loading" />

        {module.isDownloadingItem && (
          <Box sx={{ mt: 4, color: "#ffffff" }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Meanwhile, you can visit:
            </Typography>
            <List>
              <ListItemButton onClick={() => navigate("/jobs")}>
                <ListItemIcon>
                  <ListIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="Jobs Page" sx={{ color: "#ffffff" }} />
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/home")}>
                <ListItemIcon>
                  <HomeIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="Home Page" sx={{ color: "#ffffff" }} />
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/courses")}>
                <ListItemIcon>
                  <SchoolIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Courses List Page"
                  sx={{ color: "#ffffff" }}
                />
              </ListItemButton>
            </List>
          </Box>
        )}
      </Box>
      <Flex
        height="100%"
        flexDirection={["column"]}
        justifyContent="center"
        width="100%"
      >
        <AppBar
          canUndo={canUndo}
          canRedo={canRedo}
          languageOptions={languageOptions}
          value={module.language}
          handleTranslate={handleCheckHasChange}
          languageOpen={anchorElLanguage}
          handleLanguageOpen={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorElLanguage(event.currentTarget);
          }}
          handleLanguageClose={() => {
            setAnchorElLanguage(null);
          }}
          isSaveDisabled={
            !hasContentChanged(module.content) &&
            module.parentElsSequenceChange?.length == 0
          }
          open={isDrawerOpen}
          handleContentSubmit={handleContentSubmit}
          role={role}
          handleUndo={handleUndo}
          handleRedo={handleRedo}
        />
        <Sidebar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            margin: 1,
            marginTop: "5px",
            width: `calc(83% - ${drawerWidth}px)`,
            marginLeft: `calc(${drawerWidth}px + 13px)`,
          }}
        >
          <Paper
            elevation={1}
            square={false}
            sx={{
              boxSizing: "border-box",
              minHeight: `calc(100vh - 82px)`,
              position: "relative",
              width: "900px",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                top: "80px",
                display: "flex",
                justifyContent: "flex-end",
                right: "8px",
                width: drawerWidth,
              }}
            >
              <IconButton
                sx={{ width: "35px", height: "35px" }}
                onClick={() => dispatch(actions.setRightSidebarOpen(true))}
              >
                <Menu height={"100%"} width="100%" />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: 2,
                paddingRight: "70px",
                display: "flex",
                paddingBottom: "240px",
                flexDirection: "column",
                justifyContent: "start",
                overflowY: "auto",
                height: "calc(100vh - 82px)",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(0, 0, 0, 0.3)",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "rgba(0, 0, 0, 0.5)",
                  },
                },
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  marginLeft: "70px",
                  fontWeight: 700,
                  fontSize: "48px",
                }}
              >
                {module.result?.title}
              </Typography>
              {editorData ? (
                <>
                  {Object.values(editorData)?.map((editorBlocks, index) => (
                    <div ref={(el) => (selectedRefs.current[index] = el)}>
                      <InView
                        as={"div"}
                        onChange={setInView}
                        threshold={0}
                        key={`editor-${Object.keys(editorData)[index]}`}
                      >
                        {({ ref }) => {
                          return (
                            <Box
                              id={`editor-${Object.keys(editorData)[index]}`}
                              ref={ref}
                              sx={{
                                color: module.creatingParentElements
                                  ? "#ffffff00"
                                  : "#333333",
                                position: "relative",
                              }}
                            >
                              <Editor
                                key={`editor-${Object.keys(editorData)[index]}`}
                                handleInitialize={handleInitialize}
                                holder={`editor-${
                                  Object.keys(editorData)[index]
                                }`}
                                data={editorBlocks}
                                // defaultValue={editorBlocks}
                                // tools={EDITOR_JS_TOOLS}
                                index={index}
                                onChange={handleEditorChange}
                              />
                            </Box>
                          );
                        }}
                      </InView>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {!module.isElementAdding ? (
                    <Editor
                      holder={`editorjs`}
                      data={{
                        time: new Date().getTime(),
                        blocks: [],
                      }}
                      index={0}
                      handleInitialize={handleInitialize}
                      onChange={handleEditorChange}
                    />
                  ) : null}
                </>
              )}
            </Box>
          </Paper>
        </Box>
        <MuiDrawer
          sx={{
            "& .MuiPaper-root": {
              marginTop: "68px",
              overflow: "hidden",
              background: "#ffffff",
              height: "100%",
            },
            "& .MuiDrawer-paper": {
              width: "290px",
              boxSizing: "border-box",
            },
          }}
          anchor="right"
          variant="persistent"
          open={isRightSidebarOpen}
        >
          <RightDrawerHeader>
            <IconButton
              onClick={() => {
                handleCloseRightSidebar();
              }}
            >
              {theme.direction === "ltr" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </RightDrawerHeader>

          <Box sx={{ width: "100%", padding: "2px", height: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={activeTabIndexValue} onChange={handleTabChange}>
                <Tab label="Comments" {...a11yProps(0)} />
                <Tab label="Module" {...a11yProps(1)} />
                <Tab label="Topic" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={activeTabIndexValue} index={0}>
              <CommentsList onCommentClick={handleCommentClick} />
            </CustomTabPanel>
            <CustomTabModulePanel value={activeTabIndexValue} index={1}>
              <Grid container rowSpacing={2} sx={{ padding: "24px" }}>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 500,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Visibility
                  </Typography>
                </Grid>
                <Grid xs={1} />
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                      color: "#1976d2",
                      cursor: "pointer",
                    }}
                  >
                    Public
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 500,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Publish
                  </Typography>
                </Grid>
                <Grid xs={1} />
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                      color: "#1976d2",
                      cursor: "pointer",
                    }}
                  >
                    September 24, 2024
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  Last Modified
                </Grid>
                <Grid xs={1} />
                <Grid item xs={6}>
                  {turnToHumanReadable(module.result?.modified_date)}
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 500,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Author
                  </Typography>
                </Grid>
                <Grid xs={1} />
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      letterSpacing: "0.00938em",
                      color: "#1976d2",
                      cursor: "pointer",
                    }}
                  >
                    Scholastico
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ py: 3 }} direction={"column"}>
                <Typography
                  gutterBottom
                  variant="h6"
                  sx={{ pl: 2 }}
                  // sx={{ fontSize: "24px", fontWeight: 600 }}
                >
                  Module Files
                </Typography>
                <Stack direction="column" spacing={2}>
                  <Accordion
                    sx={{ "&.MuiAccordion-root": { marginTop: "8px" } }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Module Image
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          rowGap: 2,
                        }}
                      >
                        {module?.result?.image_url !== "NA" ? (
                          <Box
                            onClick={() =>
                              handleOpenImageViewerModal("isImage")
                            }
                            sx={{
                              border: "2px solid #e0e0e0",
                              borderRadius: "12px",
                              cursor: "pointer",
                              height: "200px",
                              width: "240px",
                            }}
                          >
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "12px",
                              }}
                              src={addTimestampToUrl(
                                module?.result?.image_url!
                              )}
                              alt="Module Image"
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: " center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                borderRadius: "12px",
                                height: "50px",
                                width: "64px",
                              }}
                            >
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "12px",
                                }}
                                src={"https://placehold.co/150x100?text=No Img"}
                              ></img>
                            </Box>
                          </Box>
                        )}
                        {(role == "admin" ||
                          role == "editor" ||
                          role == "super_user") && (
                          <>
                            <Button
                              startIcon={<ImageIcon />}
                              onClick={() => {
                                handleDownloadFile(
                                  "isImage",
                                  module.result?.id
                                );
                              }}
                            >
                              {module.result?.image_url &&
                              module.result.image_url != "NA"
                                ? "regenerate image"
                                : "generate Image"}
                            </Button>
                            <Button
                              startIcon={<BackupIcon />}
                              onClick={() =>
                                handleOpen({
                                  isImage: true,
                                  id: module?.result?.id!,
                                })
                              }
                              variant="contained"
                            >
                              upload image
                            </Button>
                          </>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <>
                    <Accordion
                      sx={{ "&.MuiAccordion-root": { marginTop: "8px" } }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        {`Module PDF`}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            rowGap: 2,
                          }}
                        >
                          {module?.result?.pdf_url !== "NA" ? (
                            <PDFViewer
                              handleClick={() =>
                                handleOpenImageViewerModal("isPdf")
                              }
                              pdfUrl={module?.result?.pdf_url!}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: " center",
                                alignItems: "center",
                                flexDirection: "column",
                                rowGap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "12px",
                                  height: "50px",
                                  width: "64px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "12px",
                                  }}
                                  src={
                                    "https://placehold.co/150x100?text=No Pdf"
                                  }
                                ></img>
                              </Box>
                            </Box>
                          )}
                          {(role == "admin" ||
                            role == "editor" ||
                            role == "super_user") && (
                            <Button
                              startIcon={<ImageIcon />}
                              onClick={() => {
                                handleDownloadFile("isPdf", module.result?.id);
                              }}
                            >
                              generate PDF
                            </Button>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ "&.MuiAccordion-root": { marginTop: "8px" } }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        {`Module PPT`}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            rowGap: 2,
                          }}
                        >
                          {module?.result?.ppt_url == "NA" && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                rowGap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "12px",
                                  height: "50px",
                                  width: "64px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "12px",
                                  }}
                                  src={
                                    "https://placehold.co/150x100?text=No PPT"
                                  }
                                  alt="No PPT"
                                />
                              </Box>
                            </Box>
                          )}
                          {(role === "admin" ||
                            role === "editor" ||
                            role === "super_user") && (
                            <>
                              <Button
                                startIcon={<CloudDownloadIcon />}
                                onClick={() =>
                                  handleDownloadPPt(module.result?.id)
                                }
                                disabled={
                                  module.result?.ppt_url == "NA" ||
                                  module.isDownloading
                                }
                              >
                                Download PPT
                              </Button>
                              <Button
                                startIcon={<BackupIcon />}
                                onClick={() =>
                                  handleOpen({
                                    isImage: false,
                                    id: module?.result?.id!,
                                  })
                                }
                                variant="contained"
                              >
                                Upload PPT
                              </Button>
                              <Button
                                startIcon={<AutorenewIcon />}
                                onClick={() =>
                                  handleDownloadFile("isPpt", module.result?.id)
                                }
                              >
                                Generate PPT
                              </Button>
                            </>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                </Stack>
              </Grid>
            </CustomTabModulePanel>
            <CustomTabPanel value={activeTabIndexValue} index={2}>
              {!!Object.keys(module?.result?.topics || {}).length && (
                <Box
                  sx={{
                    padding: "24px 0px",
                  }}
                >
                  {Object.keys(module?.result?.topics || {}).map((topicId) => (
                    <Stack sx={{ py: 3, width: "280px" }} direction={"column"}>
                      <Typography gutterBottom variant="h5" sx={{ pl: 2 }}>
                        {module?.result?.topics?.[topicId]?.title}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h6"
                        sx={{ pl: 2 }}
                        // sx={{ fontSize: "24px", fontWeight: 600 }}
                      >
                        Topic Files
                      </Typography>
                      <Stack direction="column" spacing={2}>
                        <Accordion
                          sx={{
                            "&.MuiAccordion-root": { marginTop: "8px" },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                          >
                            {`Topic PPT`}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                rowGap: 2,
                              }}
                            >
                              {module?.result?.topics?.[topicId].ppt_url ==
                                "NA" && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    rowGap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "12px",
                                      height: "50px",
                                      width: "64px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "12px",
                                      }}
                                      src={
                                        "https://placehold.co/150x100?text=No PPT"
                                      }
                                      alt="No PPT"
                                    />
                                  </Box>
                                </Box>
                              )}
                              {(role === "admin" ||
                                role === "editor" ||
                                role === "super_user") && (
                                <>
                                  <Button
                                    startIcon={<CloudDownloadIcon />}
                                    onClick={() => handleDownloadPPt(topicId)}
                                    disabled={
                                      module?.result?.topics?.[topicId]
                                        .ppt_url == "NA" ||
                                      module?.isDownloading
                                    }
                                  >
                                    Download PPT
                                  </Button>
                                  <Button
                                    startIcon={<BackupIcon />}
                                    onClick={() =>
                                      handleOpen({
                                        isImage: false,
                                        id: topicId,
                                      })
                                    }
                                    variant="contained"
                                  >
                                    Upload PPT
                                  </Button>
                                  <Button
                                    startIcon={<AutorenewIcon />}
                                    onClick={() =>
                                      handleDownloadFile("isPpt", topicId)
                                    }
                                  >
                                    Generate PPT
                                  </Button>
                                </>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          sx={{
                            "&.MuiAccordion-root": { marginTop: "8px" },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            Topic Image
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                rowGap: 2,
                              }}
                            >
                              {module?.result?.topics?.[topicId]?.img_url !==
                              "NA" ? (
                                <>
                                  <Box
                                    onClick={(ev) => {
                                      ev.stopPropagation();
                                      handleOpenImageViewerModal(
                                        "isImage",
                                        module?.result?.topics?.[topicId]
                                          ?.img_url
                                      );
                                    }}
                                    sx={{
                                      border: "2px solid #e0e0e0",
                                      borderRadius: "12px",
                                      cursor: "pointer",
                                      height: "200px",
                                      width: "240px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "12px",
                                      }}
                                      src={addTimestampToUrl(
                                        module?.result?.topics?.[topicId]
                                          ?.img_url!
                                      )}
                                      alt="Topic Image"
                                    />
                                  </Box>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: " center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    rowGap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "12px",
                                      height: "50px",
                                      width: "64px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "12px",
                                      }}
                                      src={
                                        "https://placehold.co/150x100?text=No Img"
                                      }
                                    ></img>
                                  </Box>
                                </Box>
                              )}
                              {(role == "admin" ||
                                role == "editor" ||
                                role == "super_user") && (
                                <>
                                  <Button
                                    startIcon={<ImageIcon />}
                                    onClick={() => {
                                      handleDownloadFile("isImage", topicId);
                                    }}
                                  >
                                    {module.result?.topics?.[topicId].img_url &&
                                    module.result?.topics?.[topicId].img_url !=
                                      "NA"
                                      ? "regenerate image"
                                      : "generate Image"}
                                  </Button>
                                  <Button
                                    startIcon={<BackupIcon />}
                                    onClick={() =>
                                      handleOpen({
                                        isImage: true,
                                        id: topicId,
                                      })
                                    }
                                    variant="contained"
                                  >
                                    upload image
                                  </Button>
                                </>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                    </Stack>
                  ))}
                </Box>
              )}
            </CustomTabPanel>
          </Box>
        </MuiDrawer>
      </Flex>
    </>
  );
}
